import { Helmet } from 'react-helmet';
import HeroImage from '../../components/HeroImage';

function ThankYouContactUs() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main className="mx-auto min-h-full">
        <section id="mainsec" className="w-full -mt-20">
          <HeroImage imageSrc="/images/contact-us/hero.webp" title="" />
        </section>

        <article className="px-4 py-16 mx-auto 2xl:container ">
          <div className="mx-2 md:mx-20 text-center">
            <div className="flex flex-row gap-6 items-center justify-center">
              <div className="text-4xl ">THANK YOU FOR CONTACTING US</div>
              <div>
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.2188 17.0625L18.5883 26.25L13.7812 21.6562"
                    stroke="black"
                    strokeWidth="2.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 36.75C29.6985 36.75 36.75 29.6985 36.75 21C36.75 12.3015 29.6985 5.25 21 5.25C12.3015 5.25 5.25 12.3015 5.25 21C5.25 29.6985 12.3015 36.75 21 36.75Z"
                    stroke="black"
                    strokeWidth="2.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="my-4    text-gtc47">
              <p>
                Dear Customer,
                <br />
                Thank you for your inquiry. {`We're`} excited to learn more about you to help make your travel dreams come true. You can
                expect to hear back from us via email within 24 hours to get started.
              </p>
            </div>
          </div>
        </article>
      </main>
    </>
  );
}

export default ThankYouContactUs;
