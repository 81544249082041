/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { AgentViewModel, StoryDetailView, StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { useParams, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import PhoneForm from '../layout/PhoneForm';
import EmailForm from '../layout/EmailForm';
import { callMe } from '../../helpers/functions';
import StoryCard from '../layout/StoryCard';
import React from 'react';

function BlogArticle() {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setStory] = useState<StoryDetailView>();
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedAgent, setSelectedAgent] = useState<AgentViewModel | null>(null);

  const onCall = (agent: AgentViewModel) => {
    setSelectedAgent(agent);
    setModalOpen('Phone');
    callMe(agent);
  };

  const agentCard = (agent: AgentViewModel) => {
    return (
      <React.Fragment key={agent.id}>
        <div className="flex flex-row gap-4  bg-white p-2 md:p-6  shadow-lg">
          <div className="flex flex-col gap-6 justify-between">
            <a href={agent.detailsUrl}>
              <img
                src={agent.photoUrl}
                className="cursor-pointer hover:opacity-40 ease-in-out duration-500 object-cover md:h-[126px] md:w-[149px] w-full h-[158px] mx-auto imgGray"
                alt={agent.fullName}
              />
            </a>
            <a
              href={agent.detailsUrl}
              className="  border border-black w-[160px] h-[55px] flex justify-center text-2xl items-center cursor-pointer
             mx-auto">
              Plan a trip
            </a>
          </div>
          <div className="flex flex-col gap-2 justify-between">
            <div>
              <div className="flex flex-col lg:flex-row gap-2">
                <div>
                  <a href={agent.detailsUrl}>
                    <div className="uppercase text-xl md:text-2xl cursor-pointer  text-left">{agent.fullName}</div>
                  </a>
                </div>
                <div className="flex flex-row items-center mb-2">
                  <div className="hidden md:block">
                    {agent.rating > 0 && (
                      <StarRatings
                        rating={agent.rating}
                        starRatedColor="#606062"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        starDimension="28"
                        starSpacing="2px"
                        name="rating"
                      />
                    )}
                  </div>
                  <div className="md:hidden">
                    {agent.rating > 0 && (
                      <StarRatings
                        rating={agent.rating}
                        starRatedColor="#606062"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        starDimension="20"
                        starSpacing="2px"
                        name="rating"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className=" text-base md:text-xl text-gtc60 text-left">{agent.interests.join(', ')}</div>
              <div className="  text-base md:text-xl text-gtc60 text-left mt-2">{agent.location}</div>{' '}
            </div>
            <div className="flex flex-row gap-6 ml-2">
              {agent.hasPhone && (
                <button
                  className={[
                    'w-[55px]  h-[55px] flex justify-center items-center  border border-black ',
                    agent.isOffline ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-white'
                  ].join(' ')}
                  disabled={agent.isOffline}
                  title={`Call ${agent.fullName}`}
                  onClick={() => onCall(agent)}
                  aria-label={`Call ${agent.fullName}`}
                  role="button"
                  tabIndex={0}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.2012 15.1124C12.198 17.1711 13.8627 18.8314 15.9238 19.823C16.0759 19.895 16.244 19.9261 16.4118 19.9134C16.5795 19.9007 16.741 19.8445 16.8805 19.7503L19.9078 17.7281C20.0416 17.6374 20.1961 17.582 20.3571 17.5672C20.518 17.5524 20.6801 17.5785 20.8281 17.6433L26.4953 20.0773C26.689 20.1579 26.8508 20.3 26.9558 20.4816C27.0609 20.6632 27.1033 20.8743 27.0766 21.0824C26.8969 22.4843 26.2126 23.7728 25.1517 24.7067C24.0908 25.6406 22.7259 26.1559 21.3125 26.1562C16.9447 26.1562 12.7558 24.4211 9.66734 21.3326C6.57885 18.2441 4.84375 14.0552 4.84375 9.68745C4.84407 8.27404 5.35938 6.90917 6.29327 5.84824C7.22716 4.78731 8.51563 4.10301 9.91758 3.92339C10.1257 3.89666 10.3368 3.93907 10.5184 4.0441C10.7 4.14913 10.842 4.31095 10.9227 4.50464L13.3566 10.1839C13.4199 10.3297 13.4464 10.4887 13.4337 10.6471C13.4211 10.8055 13.3696 10.9583 13.284 11.0921L11.2617 14.1679C11.1717 14.307 11.1188 14.4669 11.1082 14.6323C11.0976 14.7977 11.1296 14.963 11.2012 15.1124V15.1124Z"
                      stroke="black"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
              {agent.hasEmail && (
                <button
                  className={[
                    'w-[55px]  h-[55px] flex justify-center items-center  border border-black ',
                    agent.isOffline ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-white'
                  ].join(' ')}
                  disabled={agent.isOffline}
                  title={`Email ${agent.fullName}`}
                  onClick={() => {
                    setSelectedAgent(agent);
                    setModalOpen('Email');
                  }}
                  aria-label={`Email ${agent.fullName}`}
                  role="button"
                  tabIndex={0}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.875 6.78125H27.125V23.25C27.125 23.5069 27.0229 23.7533 26.8413 23.935C26.6596 24.1167 26.4132 24.2188 26.1562 24.2188H4.84375C4.58682 24.2188 4.34042 24.1167 4.15874 23.935C3.97706 23.7533 3.875 23.5069 3.875 23.25V6.78125Z"
                      stroke="black"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M27.125 6.78125L15.5 17.4375L3.875 6.78125"
                      stroke="black"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const getStory = () => {
    if (id) {
      const parsed = queryString.parse(searchParams.toString());
      console.log(parsed);
      setLoading(true);
      APIService()
        .gtcService.fetchStory(parseInt(id), parsed.preview ? (parsed.preview as unknown as boolean) : false)
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setStory(res.data);
          } else {
            setStory(undefined);
          }
        });
    }
  };

  useEffect(() => {
    Modal.setAppElement('#root');
    getStory();
  }, []);

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
        <meta name="title" content={`${data?.story.title} | Global Travel Collection`} />
        <meta name="description" content={data?.story?.shortDescription.slice(0, 180)} />
        <title>{data?.story.title || ''} | Global Travel Collection</title>
      </Helmet>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
        </div>
      )}
      {data && (
        <div className="">
          <article className="p-2 lg:py-4 lg:px-4 mx-auto  2xl:container mt-20">
            <section className="mx-2 md:mx-14 mb-4 md:mb-10">
              <a href={`/advisor-inspiration`} className="font-light text-xl mt-2 flex flex-row gap-2 items-center self-start text-gtc60">
                <svg width="31" height="12" viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mt-1">
                  <path
                    d="M0.469667 5.46967C0.176775 5.76256 0.176775 6.23743 0.469667 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46446 6.59619 0.98959 6.3033 0.696697C6.01041 0.403804 5.53553 0.403804 5.24264 0.696697L0.469667 5.46967ZM31 5.25L0.999998 5.25L0.999998 6.75L31 6.75L31 5.25Z"
                    fill="#606062"
                  />
                </svg>
                Back to Inspiration page
              </a>
              <div
                className="large-hero justify-center items-center flex mt-4"
                role="img"
                aria-label="hero img"
                style={{
                  backgroundImage: `url(${data.story.heroImageUrl})`,
                  backgroundSize: 'cover',
                  boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.2)'
                }}>
                <div className="flex flex-col self-end  bg-white w-full opacity-80 h-[107px] justify-center">
                  <div>
                    <h1 className="text-2xl px-2 md:px-0 md:text-4xl text-gtc4 text-center ">{data.story.title}</h1>
                  </div>
                </div>
              </div>

              <section className="mt-4 flex flex-col sm:flex-row gap-4 lg:items-center">
                <div className="flex-1  tracking-wider leading-9 text-xl md:text-lg font-light">
                  <div className="w-full">
                    <div className="mt-6 md:mt-8 float-left text-[80px] md:text-[100px]"> {data.story.shortDescription.charAt(0)}</div>
                    <p className=""> {data.story.shortDescription.substring(1)}</p>
                  </div>
                </div>
                <div className="order-first sm:order-last">
                  <img
                    src={data.story.thumbImageUrl}
                    alt={data.story.thumbImageDescription}
                    className="blog-summary-thumbnail-image mx-auto h-[310px] w-[205px] object-cover"
                  />
                </div>
              </section>
              <section className="mt-4 divBlogStory" dangerouslySetInnerHTML={{ __html: data.story.storyBody }} />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4  w-full mt-20">
                {data.relatedAgents.slice(0, 2).map((agent) => (
                  <div key={agent.id}>{agentCard(agent)}</div>
                ))}
              </div>
              {data.story.relatedStories.length > 0 && (
                <section className="mt-14 flex flex-col justify-center w-full items-center gap-4">
                  <h2 className="uppercase mb-4 text-4xl  ">Related Articles</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
                    {data.story.relatedStories.slice(0, 3).map((story) => (
                      <StoryCard key={story.id} story={story} />
                    ))}
                  </div>
                </section>
              )}
            </section>
          </article>
        </div>
      )}

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {selectedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...selectedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && <PhoneForm agent={selectedAgent} callback={() => setModalOpen('Email')} />}
              {modalOpen === 'Email' && <EmailForm agent={selectedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default BlogArticle;
