/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PromoItemViewModel, StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import GetInspired from '../layout/advisor-offer/GetInspired';
import { blogTypeToPromoType } from '../../helpers/functions';
import HeroImage from '../../components/HeroImage';
import DefaultButton from '../../components/DefaultButton';
import StoryCard from '../layout/StoryCard';

interface Category {
  name: string;
  img: string;
  // title: string;
}

const categories: Category[] = [
  {
    name: 'Honeymoons',
    // title: 'Honeymoons articles',
    img: '/images/story/1.webp'
  },

  {
    img: '/images/story/2.webp',
    name: 'Family Holidays'
  },

  {
    img: '/images/story/5.webp',
    name: 'All Inclusive'
  },
  {
    img: '/images/story/3.webp',
    name: 'Private Villas'
  },

  {
    img: '/images/story/4.webp',
    name: 'Adult Only'
  },
  {
    img: '/images/story/7.webp',
    name: 'Spa & Wellness'
  },
  {
    img: '/images/story/8.webp',
    name: 'City Breaks'
  },
  {
    img: '/images/story/9.webp',
    name: 'Bucket List Trips'
  },
  {
    img: '/images/story/6.webp',
    name: 'Cruise & Luxury Yacht'
  },
  {
    img: '/images/story/10.webp',
    name: 'Wildlife & Safari'
  },
  {
    img: '/images/story/11.webp',
    name: 'Beach Holidays'
  },
  {
    img: '/images/story/12.webp',
    name: 'Private Jet'
  }
];

function AdvisorOffers() {
  // eslint-disable-next-line no-unused-vars
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [promos, setPromos] = useState<PromoItemViewModel[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingStories, setLoadingStories] = useState<boolean>(false);

  const [showAll, setShowAll] = useState<boolean>(false);

  const [stories, setStories] = useState<StoryItemViewModel[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const parsed = queryString.parse(window.location.search);

  const getStories = () => {
    setLoadingStories(true);
    APIService()
      .gtcService.fetchStories(selectedCategory?.name, 99)
      .then((res) => {
        setLoadingStories(false);
        if (res.responseStatus === 1) {
          setStories(res.data);
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    if (parsed?.type !== undefined) {
      console.log(parsed?.type);
      if (categories.find((x) => x.name === parsed.type) !== undefined) {
        setSelectedCategory(categories.find((x) => x.name === parsed.type));
      } else {
        setSelectedCategory(
          //@ts-ignore
          {
            name: 'ALL'
          }
        );
      }
    } else {
      setSelectedCategory(
        //@ts-ignore
        {
          name: 'ALL'
        }
      );
    }
  }, [searchParams]);
  useEffect(() => {
    if (selectedCategory != undefined && selectedCategory.name != 'ALL') getStories();
  }, [selectedCategory]);

  // useEffect(() => {
  //   if (selectedCategory != undefined) getPromos();
  // }, [selectedCategory]);
  return (
    <div className="">
      <Helmet>
        <meta name="title" content="Find Your Next Dream holiday | Global Travel Collection " />
        <meta
          name="description"
          content="When you work with a Global Travel Collection advisor, you receive access to exclusive
          perks, upgrades, and experiences. No matter how you choose to travel, Global Travel
          Collection will help you plan an unforgettable experience that’s right for you."
        />
        <title>Find Your Next Dream holiday | Global Travel Collection</title>
        <link rel="canonical" href="/advsior-offers"></link>
      </Helmet>
      <section id="mainsec" className="w-full ">
        <HeroImage title={selectedCategory?.name + ' Articles' || ''} imageSrc={selectedCategory?.img || ''} />
      </section>

      <article className="px-4 md:px-14 mx-auto 2xl:container mt-4 mb-14 items-center flex flex-col">
        <a href={`/`} className="font-light text-xl mt-2 flex flex-row gap-2 items-center self-start">
          <svg width="31" height="12" viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-1">
            <path
              d="M0.469667 5.46967C0.176775 5.76256 0.176775 6.23743 0.469667 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46446 6.59619 0.98959 6.3033 0.696697C6.01041 0.403804 5.53553 0.403804 5.24264 0.696697L0.469667 5.46967ZM31 5.25L0.999998 5.25L0.999998 6.75L31 6.75L31 5.25Z"
              fill="#606062"
            />
          </svg>
          <span>Back to Homepage</span>
        </a>
        <section className="my-10 flex flex-col items-center w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full ">
            {loading ? (
              <div className="w-full h-[800px] z-50 overflow-hidden bg-gray-700 opacity-10 flex flex-col items-center justify-center col-span-2">
                <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
                {/* <h2 className="text-center text-white text-xl font-semibold">Loading...</h2> */}
              </div>
            ) : (
              <>
                {stories.map((x) => (
                  <StoryCard key={x.id} story={x} />
                ))}
              </>
            )}
          </div>
        </section>
        <DefaultButton title="READ MORE ARTICLES" onClick={() => navigate('/advisor-inspiration')} />
      </article>
    </div>
  );
}

export default AdvisorOffers;
