import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AgentDetailViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import ClientReview from '../layout/agent/ClientReview';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import ClientTestimonial from '../layout/agent/ClientTestimonial';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import EmailForm from '../layout/EmailForm';
import PhoneForm from '../layout/PhoneForm';
// import AgentConnectionView from '../layout/agent/AgentConnection';
import queryString from 'query-string';
import { AgentMapView } from '../layout/agent/AgentMap';
import TravelExperience from '../layout/agent/TravelExperience';
// eslint-disable-next-line no-unused-vars
import { getStateName } from '../../helpers/functions';
import AllTravelExperience from '../layout/agent/AllTravelExperience';
// eslint-disable-next-line no-unused-vars
import StarRatings from 'react-star-ratings';

function Blog() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<AgentDetailViewModel | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  const parsed = queryString.parse(searchParams.toString());

  const getAgent = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchAgentBlog({
        agentId: parseInt(parsed.agentId as string),
        bioId: parsed.bioId as string | null,
        blogId: parsed.blogId as string | null,
        preview: parsed.preview ? (parsed.preview as unknown as boolean) : false
      })
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setData(res.data);
        }
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(searchParams.toString());
    console.log(parsed);
    if (parseInt(parsed.agentId as string) > 0) getAgent();
  }, [id]);

  const agentCard = useMemo(() => {
    return (
      <div className="flex flex-col bg-white shadow-lg">
        <div className="flex flex-col text-sm justify-center bg-gtc6 md:w-[380px] mx-1 md:mx-0">
          <div>
            <img
              src={data?.agent.photoUrl}
              className=" bg-cover w-[380px] h-[321px] imgGray object-cover"
              alt={`${data?.agent.fullName} ${data?.agent.city}, ${data?.agent.state} Luxury Travel Agent`}
            />
          </div>
          <div className="flex flex-col gap-2 justify-center px-6 py-6">
            <div className="text-xl text-center text-gtc60">{data?.agent.interests.join(', ')}</div>
            <div className="flex flex-row gap-4 justify-center my-2">
              {data?.agent.hasPhone && (
                <button
                  className={[
                    'w-[55px]  h-[55px] flex justify-center items-center  border border-black ',
                    data?.agent.isOffline ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-black'
                  ].join(' ')}
                  disabled={data?.agent.isOffline}
                  title={`Call ${data?.agent.fullName}`}
                  onClick={() => setModalOpen('Phone')}
                  aria-label={`Call ${data?.agent.fullName}`}
                  role="button"
                  tabIndex={0}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.2012 15.1124C12.198 17.1711 13.8627 18.8314 15.9238 19.823C16.0759 19.895 16.244 19.9261 16.4118 19.9134C16.5795 19.9007 16.741 19.8445 16.8805 19.7503L19.9078 17.7281C20.0416 17.6374 20.1961 17.582 20.3571 17.5672C20.518 17.5524 20.6801 17.5785 20.8281 17.6433L26.4953 20.0773C26.689 20.1579 26.8508 20.3 26.9558 20.4816C27.0609 20.6632 27.1033 20.8743 27.0766 21.0824C26.8969 22.4843 26.2126 23.7728 25.1517 24.7067C24.0908 25.6406 22.7259 26.1559 21.3125 26.1562C16.9447 26.1562 12.7558 24.4211 9.66734 21.3326C6.57885 18.2441 4.84375 14.0552 4.84375 9.68745C4.84407 8.27404 5.35938 6.90917 6.29327 5.84824C7.22716 4.78731 8.51563 4.10301 9.91758 3.92339C10.1257 3.89666 10.3368 3.93907 10.5184 4.0441C10.7 4.14913 10.842 4.31095 10.9227 4.50464L13.3566 10.1839C13.4199 10.3297 13.4464 10.4887 13.4337 10.6471C13.4211 10.8055 13.3696 10.9583 13.284 11.0921L11.2617 14.1679C11.1717 14.307 11.1188 14.4669 11.1082 14.6323C11.0976 14.7977 11.1296 14.963 11.2012 15.1124V15.1124Z"
                      stroke="white"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
              {data?.agent.hasEmail && (
                <button
                  className={[
                    'w-[55px]  h-[55px] flex justify-center items-center  border border-black ',
                    data?.agent.isOffline ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-black'
                  ].join(' ')}
                  disabled={data?.agent.isOffline}
                  title={`Email ${data?.agent.fullName}`}
                  onClick={() => {
                    setModalOpen('Email');
                  }}
                  aria-label={`Email ${data?.agent.fullName}`}
                  role="button"
                  tabIndex={0}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.875 6.78125H27.125V23.25C27.125 23.5069 27.0229 23.7533 26.8413 23.935C26.6596 24.1167 26.4132 24.2188 26.1562 24.2188H4.84375C4.58682 24.2188 4.34042 24.1167 4.15874 23.935C3.97706 23.7533 3.875 23.5069 3.875 23.25V6.78125Z"
                      stroke="white"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M27.125 6.78125L15.5 17.4375L3.875 6.78125"
                      stroke="white"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>

          {data?.agent.isOffline && (
            <div className="flex-1 flex flex-col pr-5 justify-center items-center text-center">
              <div className="font-bold text-base md:text-lg">{data?.agent.isOfflineMsg}</div>
              <div className="text-base md:text-lg">
                Have an Emergency?
                <br />
                <button
                  onClick={() => {
                    setModalOpen('Email');
                  }}
                  className="text-gtc7 hover:text-black text-sm cursor-pointer"
                  role="button">
                  Contact Me Here
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [data]);
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
        <meta name="title" content={data?.pageTitle || ''} />
        <meta name="description" content={data?.pageDescription} />
        <title>{data?.pageTitle || ''}</title>
      </Helmet>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Fetching...</h2>
        </div>
      )}
      {data && (
        <div className="p-2 lg:py-4 lg:px-14 mx-auto  2xl:container mt-20">
          <a
            href={`/find-an-elite-travel-agent`}
            className="font-light text-xl mt-2 flex flex-row gap-2 items-center self-start text-gtc60">
            <svg width="31" height="12" viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mt-1">
              <path
                d="M0.469667 5.46967C0.176775 5.76256 0.176775 6.23743 0.469667 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46446 6.59619 0.98959 6.3033 0.696697C6.01041 0.403804 5.53553 0.403804 5.24264 0.696697L0.469667 5.46967ZM31 5.25L0.999998 5.25L0.999998 6.75L31 6.75L31 5.25Z"
                fill="#606062"
              />
            </svg>
            Back to Find an Advisor
          </a>
          {/* <div className="text-base text-black gap-1 bg-gtc8 p-1.5">
            <a href="/find-an-elite-travel-agent" className="pr-1">
              Home {'>'}
            </a>
            <a href={`/find-an-elite-travel-agent?slctState=${data.agent.state}`} className="pr-1">
              {getStateName(data.agent.state)} {'>'}
            </a>
            <a href={`/travel-agent/${data.agent.id}`} className="pr-1">
              {data.agent.fullName} {'>'}
            </a>
            <a href={`/travel-agent/${data.agent.id}/${data.agent.websiteDisplayTitleSlug}?bioId=${parsed.bioId || 0}`} className="pr-1">
              {data.agent.websiteDisplayTitle} {'>'}
            </a>
            {parsed.blogId ? (
              <a href={`/travel-agent/agent-blog?agentId=${data.agent.id}`}>All Travel Experiences {'>'}</a>
            ) : (
              <div className="text-gtc9 inline">All Travel Experiences</div>
            )}

            <div className="text-gtc9 inline">{data.agent.title}</div>
          </div> */}
          <div className="flex flex-col md:flex-row gap-4 my-4">
            <div className="text-6xl text-black">{agentCard}</div>
            <div className="text-black md:mr-10 flex flex-col gap-6 px-2 md:px-0 min-w-0">
              <div className="flex flex-col gap-2 mt-1 border-gtc99 border-b pb-6" aria-label={data.agent.fullName} role="main">
                <div className="flex flex-col md:flex-row gap-1 md:gap-4">
                  <h1 className="uppercase text-2xl md:text-4xl font-light m-0">{data.agent.fullName}</h1>
                  {data.agent.rating > 0 && (
                    <StarRatings
                      rating={data.agent.rating}
                      starRatedColor="#606062"
                      // changeRating={this.changeRating}
                      numberOfStars={5}
                      starDimension="28"
                      starSpacing="2px"
                      name="rating"
                    />
                  )}
                </div>
                <div className="text-lg text-gtc60">
                  {data.agent.city}, {getStateName(data.agent.state)} Travel Agent
                </div>
              </div>
              {data.agent.title !== '' && (
                <div className="flex flex-col gap-5 pb-4 md:mt-4">
                  <div className="flex flex-col gap-4 bg-white p-2 md:p-8 shadow-lg">
                    <div className="text-xl md:text-2xl uppercase font-medium">{data.agent.title}</div>
                    <div className="text-xl text-gtc47" dangerouslySetInnerHTML={{ __html: data.agent.bioText }} />
                  </div>
                </div>
              )}

              {data.showSpecialties && (
                <div className="flex flex-col gap-8 border-gtc99 border-b  pb-8">
                  <h2 className="font-semibold text-xl uppercase">Travel Specialties</h2>
                  <div className="flex flex-col md:flex-col gap-6 ">
                    {data.specialities?.map((x) => (
                      <div className="flex flex-col lg:flex-row  gap-0  text-center" key={x.title}>
                        <a href={x.url}>
                          <div
                            className="hover:opacity-40 ease-in-out duration-500 
                           w-fit">
                            <img src={x.image} alt={x.imageCaption} className="object-cover w-[191px] h-[196px]" />
                          </div>
                        </a>
                        <div className="flex flex-col text-sm flex-1  p-4">
                          <a href={x.url} className="text-xl font-semibold text-left">
                            {x.title}
                          </a>
                          <div>
                            <a
                              href={x.url}
                              className="text-lg text-gtc47 text-left leading-6"
                              dangerouslySetInnerHTML={{
                                __html: x.shortText.concat(
                                  `<a href=${x.url} class="text-black font-bold">Read more from my ${x.title} bio.</a>`
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {data.certifications && data.certifications?.length > 0 && (
                <div className="flex flex-col gap-4 border-gtc3 border-b pb-4">
                  <h2 className="font-semibold text-xl uppercase">Certificates & Awards</h2>
                  <div className="grid grid-cols-3 md:mx-0 mx-auto md:flex flex-col md:flex-row gap-4 ">
                    {data.certifications?.map((x) => (
                      <div
                        className="w-24 h-24 p-2 text-center tracking-normal text-white bg-black text-xs flex items-center"
                        key={x.certification}>
                        <span className="break-all">{x.certification}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {data.showExperiences && <TravelExperience data={data} />}
              {!data.showExperiences && parsed.blogId === undefined && <AllTravelExperience data={data} />}
              {data.showTestimonials && data.testimonials && data.testimonials?.length > 0 && (
                <div className="border-gtc3 border-b pb-4">
                  <ClientTestimonial testimonials={data.testimonials || []} />
                </div>
              )}
              {data.showReviews && data.reviews && data.reviews?.length > 0 && (
                <div className="border-gtc3 border-b pb-4">
                  <ClientReview reviews={data.reviews || []} agent={data.agent} onReviewFinish={getAgent} />
                </div>
              )}
              {data.showMap && <AgentMapView data={data} />}
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {data?.agent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...data?.agent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && (
                // eslint-disable-next-line react/jsx-no-undef
                <PhoneForm agent={data?.agent} callback={() => setModalOpen('Email')} />
              )}
              {modalOpen === 'Email' && <EmailForm agent={data?.agent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Blog;
