import { Helmet } from 'react-helmet';
import HeroImage from '../../components/HeroImage';

function ThankYouJoinUs() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main className="mx-auto min-h-full">
        <section id="mainsec" className="w-full -mt-20">
          <HeroImage imageSrc="/images/join-us/hero-thankyou.webp" title="" />
        </section>

        <article className="px-4 py-2 mx-auto  2xl:container">
          <a href={`/join-us`} className="font-light text-xl mt-2 flex flex-row gap-2 items-center self-start">
            <svg width="31" height="12" viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mt-1">
              <path
                d="M0.469667 5.46967C0.176775 5.76256 0.176775 6.23743 0.469667 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46446 6.59619 0.98959 6.3033 0.696697C6.01041 0.403804 5.53553 0.403804 5.24264 0.696697L0.469667 5.46967ZM31 5.25L0.999998 5.25L0.999998 6.75L31 6.75L31 5.25Z"
                fill="#606062"
              />
            </svg>
            Back to Join Us
          </a>
          <div className="py-16 mx-2 md:mx-20 text-center">
            <div className="flex flex-row gap-6 items-center justify-center">
              <div className="text-4xl ">THANK YOU FOR CONNECTING</div>
              <div>
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.2188 17.0625L18.5883 26.25L13.7812 21.6562"
                    stroke="black"
                    strokeWidth="2.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 36.75C29.6985 36.75 36.75 29.6985 36.75 21C36.75 12.3015 29.6985 5.25 21 5.25C12.3015 5.25 5.25 12.3015 5.25 21C5.25 29.6985 12.3015 36.75 21 36.75Z"
                    stroke="black"
                    strokeWidth="2.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="my-4  text-gtc47">
              <p>
                Dear Customer,
                <br />
                Thank you for your inquiry. {`We're`} excited to learn more about you to help make your travel dreams come true. You can
                expect to hear back from us via email within 24 hours to get started.
              </p>
            </div>
          </div>
        </article>
      </main>
    </>
  );
}

export default ThankYouJoinUs;
