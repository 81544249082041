import { useState, useEffect } from 'react';
import { StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { Helmet } from 'react-helmet';
import HeroImage from '../../components/HeroImage';
import DefaultButton from '../../components/DefaultButton';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import StoryCard from '../layout/StoryCard';

function AdvisorInspiration() {
  const [stories, setStories] = useState<StoryItemViewModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAll, setShowAll] = useState<boolean>(false);
  // const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const getStories = () => {
    const parsed = queryString.parse(searchParams.toString());
    console.log(parsed);

    let type = parsed?.type ? (parsed.type as string) : '';
    setLoading(true);
    APIService()
      .gtcService.fetchStories(encodeURIComponent(type), 99)
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          var d = res.data;
          setStories(d);
        } else {
          setStories([]);
        }
      });
  };

  useEffect(() => {
    getStories();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="title" content="Luxury Travel Experience Inspiration and Ideas | Global Travel Collection" />
        <meta
          name="description"
          content="Global Travel Collection’s stories bring you a world without borders. See ideas and inspriations from luxury expeirences around the world brought to you by agents and travelers."
        />
        <title>Luxury Travel Experience Inspiration and Ideas | Global Travel Collection</title>
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>
      <section id="mainsec" className="w-full -mt-20">
        <HeroImage imageSrc="/images/inspiration/hero.webp" title="Inspiration" />
      </section>
      <section>
        <div className=" py-4 md:py-16 items-center justify-center text-center  mx-auto  2xl:container px-3 md:px-0">
          <div className="mx-0 md:mx-20 flex flex-col gap-6">
            <h2 className="uppercase text-3xl md:text-4xl ">{`Let's go somewhere`}</h2>
            <div className="text-xl  text-gtc47">
              Whether you choose cruise, an intimate getaway or luxurious resorts fit for the entire family our inspirational blogs will
              lead you to a trip of a lifetime.
            </div>
          </div>
        </div>
      </section>
      <section className=" mx-auto  2xl:container mt-2 md:mt-0">
        <div className="mx-0 md:mx-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4  w-full">
            {stories.slice(0, 4).map((x) => (
              <div key={x.id + '-featured'}>
                <div
                  className="relative shadow-lg h-[350px]"
                  aria-label="hero img"
                  style={{
                    backgroundImage: `url(${x.thumbnailImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '50%',
                    boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.3)'
                  }}>
                  {/* <a title={x.title} href={x.url}>
                    <img
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
                      className="w-full object-cover h-[350px]"
                      src={x.thumbnailImage}
                      alt={x.title}
                    />
                  </a> */}

                  <div
                    className="text-center  min-h-[160px] py-4 flex flex-col 
                        justify-center items-center absolute w-full bottom-0 px-1 md:px-4 gap-4">
                    <div className="text-3xl md:text-4xl text-white uppercase whitespace-nowrap overflow-ellipsis overflow-hidden w-80 md:w-[400px]">
                      {x.title}
                    </div>

                    <a
                      title={x.title + '-read more'}
                      href={x.url}
                      className="uppercase hover:underline text-white cursor-pointer text-2xl  ">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="mx-auto  2xl:container my-8 md:my-14">
        {loading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
            <h2 className="text-center text-white text-xl font-semibold">Searching...</h2>
          </div>
        ) : (
          <section className="mx-0 md:mx-20">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 w-full">
              {stories.slice(4, 10).map((x) => (
                <StoryCard key={x.id} story={x} />
              ))}
            </div>

            <div className={`${showAll ? 'max-h-[50000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 w-full mt-8">
                {stories.slice(10, stories.length).map((x) => (
                  <StoryCard key={x.id} story={x} />
                ))}
              </div>
            </div>
          </section>
        )}

        {stories.length > 10 && !loading && (
          <div className="flex justify-center mt-14">
            <DefaultButton title={showAll ? 'View Fewer' : 'View More'} onClick={() => setShowAll(!showAll)} />
          </div>
        )}
      </section>
    </>
  );
}

export default AdvisorInspiration;
