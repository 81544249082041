/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { AgentViewModel, ReviewsEntity } from '../../../helpers/types';
import React from 'react';
import APIService from '../../../services/services';
import { toast } from 'react-toastify';

const itemToShow = 5;

interface ClientReviewProps {
  reviews: ReviewsEntity[];
  agent: AgentViewModel;
  onReviewFinish: () => void;
}

function ClientReview({ reviews, agent, onReviewFinish }: ClientReviewProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showFull, setShowFull] = useState(false);
  const isRequestedBefore = (reviewId: string) => {
    if (localStorage.getItem(`review-${reviewId}`)) {
      return true;
    }
    return false;
  };

  const sendHelpful = (reviewId: string, value: boolean) => {
    if (!loading) {
      setLoading(true);
      APIService()
        .gtcService.sendReview(reviewId, value, parseInt(agent.id))
        .then((response) => {
          if (response.responseStatus === 1) {
            localStorage.setItem(`review-${reviewId}`, value.toString());

            toast.success('Thank you for your vote!');
            onReviewFinish();
          } else {
            toast(response.error.message);
          }
          setLoading(false);
        });
    }
  };

  const renderReview = (x: ReviewsEntity) => (
    <div className="flex flex-col justify-center gap-1 text-sm">
      <div className="italic">
        {x.helpful} of {x.totalRated} people found this review helpful
      </div>
      <div className="">
        Reviewed By{' '}
        <strong>
          {x.customerNickname} - {x.customerLocation}
        </strong>{' '}
        | Traveled to <strong>{x.customerDestination}</strong>
      </div>
      <div className="text-red-400">{x.title}</div>
      <div dangerouslySetInnerHTML={{ __html: x.review }} />
      <div className="">
        {x.recommended === 'Y' ? (
          <div className="flex flex-row items-center font-bold gap-1">
            <i role="presentation" className="fa fa-check text-green-600"></i> I would recommend this agent to my family and friends
          </div>
        ) : (
          <div>
            <b>I would not recommend this agent to my family and friends</b>
          </div>
        )}
        {x.agentResponse !== '' && (
          <div className="flex flex-col gap-1 mt-1 ml-4">
            <strong>{agent.fullName} responded:</strong>

            <i>{x.agentResponse}</i>
          </div>
        )}
      </div>

      <div className="text-gtc1 my-2">
        Was this review helpful to you?
        {isRequestedBefore(x.id.toString()) ? (
          <span className="text-gray-900 italic">You have already voted on this review.</span>
        ) : (
          <>
            {loading ? (
              <span className="text-gray-900 px-1">Sending..</span>
            ) : (
              <>
                <span
                  className={[isRequestedBefore(x.id.toString()) ? 'text-gray-900 italic' : 'cursor-pointer', ' px-1'].join(' ')}
                  onClick={() => (!isRequestedBefore(x.revId) ? sendHelpful(x.id.toString(), true) : {})}
                  role="button"
                  tabIndex={0}>
                  YES
                </span>
                |
                <span
                  className={[isRequestedBefore(x.id.toString()) ? 'text-gray-900 italic' : ' cursor-pointer', ' px-1 '].join(' ')}
                  onClick={() => (!isRequestedBefore(x.revId) ? sendHelpful(x.id.toString(), false) : {})}
                  role="button"
                  tabIndex={0}>
                  NO
                </span>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <h2 className="font-bold text-3xl mt-0 mb-0">Reviews</h2>
      <div className="flex flex-col gap-4">
        {reviews.slice(0, itemToShow)?.map((x) => (
          <React.Fragment key={x.revId}>{renderReview(x)}</React.Fragment>
        ))}
        <div className={`${showFull ? 'max-h-[5000px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
          {reviews.slice(itemToShow, reviews.length)?.map((x) => (
            <React.Fragment key={x.revId}>{renderReview(x)}</React.Fragment>
          ))}
        </div>

        {reviews.length > 5 && (
          <button
            type="button"
            className="text-sm  cursor-pointer font-semibold text-black hover:text-black  self-end md:self-start w-full"
            onClick={() => setShowFull(!showFull)}>
            {!showFull ? (
              <div
                className="py-2 font-normal text-center text-white bg-black border border-black w-full
               hover:font-semibold px-7 hover:cursor-pointer">
                SHOW MORE REVIEWS
              </div>
            ) : (
              <div className="flex flex-row gap-1 items-center">
                Show less reviews
                <i role="presentation" className="fa fa-angle-double-up"></i>
              </div>
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export default ClientReview;
