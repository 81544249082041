/* eslint-disable no-unused-vars */

import { AgentDetailViewModel } from '../../../helpers/types';
import 'react-image-gallery/styles/css/image-gallery.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';

interface ItineraryProps {
  data: AgentDetailViewModel;
  title?: string;
}

function AgentStories({ data, title = 'Travel Stories' }: ItineraryProps) {
  const stories = data.experiences || [];

  const cardWidth = stories.length < 3 ? 'w-full md:w-full' : 'w-full';
  const cardWrapper = () => {
    switch (stories.length) {
      case 3:
        return 'md:px-0  md:mr-0';
      case 2:
        return '';
      case 1:
        return '';

      default:
        return '';
    }
  };
  return (
    <>
      {stories.length > 0 && (
        <div className=" border-gtc3 border-b pb-4 relative">
          <h2 className="font-bold text-2xl mt-0 mb-2">{title}</h2>
          {stories.length > 3 && (
            <>
              <div className="absolute inset-y-0 left-0 z-10 hidden-tw lg:flex items-center button-prev">
                <button className="bg-transparent  -ml-8 flex justify-center items-center  focus:outline-none">
                  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="30" height="48" viewBox="0 0 30 48" fill="none">
                    <rect width="30" height="48" fill="#C0C0C0" />
                    <g id="Profile - 1440 px" clipPath="url(#clip0_1072_24882)">
                      <rect width="1440" height="4017" transform="translate(-404 -1119)" fill="#f2f2f2" />
                      <g id="Group 1788">
                        <g id="Group 1787">
                          <g id="Arrows">
                            <path
                              id="Vector"
                              d="M30 5.64L11.4575 24L30 42.36L24.2915 48L2.08066e-06 24L24.2915 -5.03246e-07L30 5.64Z"
                              fill="#D9D9D9"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_24882">
                        <rect width="1440" height="4017" fill="#f2f2f2" transform="translate(-404 -1119)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className="absolute inset-y-0 right-0 z-10 hidden-tw lg:flex items-center button-next">
                <button className="bg-transparent  -mr-2 lg:-mr-8 flex justify-center items-center  focus:outline-none">
                  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="30" height="48" viewBox="0 0 30 48" fill="none">
                    <path
                      id="Vector"
                      d="M6.19703e-08 42.36L18.5425 24L8.251e-09 5.64L5.7085 0L30 24L5.7085 48L6.19703e-08 42.36Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}
          <div className={['relative  flex-row px-0 ', cardWrapper(), stories.length > 3 ? 'lg:mx-10' : 'md:mx-auto '].join(' ')}>
            <Swiper
              spaceBetween={20}
              slidesPerView={1.5}
              loop={false}
              slidesPerGroupSkip={1}
              modules={[Navigation]}
              navigation={{
                prevEl: '.button-prev',
                nextEl: '.button-next'
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  centeredSlidesBounds: false
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  centeredSlidesBounds: false
                },
                1024: {
                  slidesPerView: 3,
                  // spaceBetween: 20,
                  centeredSlides: false,
                  centeredSlidesBounds: false
                }
              }}>
              <div className="px-20">
                {stories.map((x, index) => (
                  <>
                    <SwiperSlide key={x.postId}>
                      <div className="swiper-slide p-1 w-[268px]">
                        <a href={x.url} className="flex flex-col justify-center items-center gap-1  w-full  text-center cursor-pointer">
                          <img className={[cardWidth, 'h-[200px] object-cover'].join(' ')} src={x.image} alt={x.imageCaption} />
                          <div className="text-sm font-bold text-left">{x.title}</div>
                          <div
                            className="text-sm font-thin text-left"
                            dangerouslySetInnerHTML={{
                              __html: x.shortText.concat(
                                `<a href="${x.url}" class="text-black cursor-pointer font-bold">Read more from my ${x.title} story.</a>`
                              )
                            }}
                          />
                        </a>
                      </div>
                    </SwiperSlide>
                  </>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
}

export default AgentStories;
