import React from 'react';
import FooterLink, { FooterLinkProps } from '../../components/FooterLink';
import { Link } from 'react-router-dom';

interface SocialLinkProps {
  icon: string;
  link: string;
  name: string;
}

const footerRightLinks: FooterLinkProps[] = [
  {
    name: 'Powered by Internova Travel Group',
    to: 'https://internova.com/'
  }
];
const footerLeftLinks: FooterLinkProps[] = [
  {
    name: 'Contact Us',
    to: '/contact-us'
  },
  {
    name: 'Join Us',
    to: '/join-us'
  },
  {
    name: 'Terms of Use',
    to: 'https://internova.com/terms-conditions/'
  },
  {
    name: 'Privacy Policy',
    to: 'https://internova.com/privacy/'
  }
];

const socialLinks: SocialLinkProps[] = [
  {
    icon: '/images/social/Instagram.svg',
    link: 'https://www.instagram.com/gtcuk_/',
    name: 'Instagram'
  },
  {
    icon: '/images/social/Linkedin.svg',
    link: 'https://www.linkedin.com/company/gtcuk',
    name: 'LinkedIn'
  }
  // {
  //   icon: '/images/social/Facebook.svg',
  //   link: 'https://www.facebook.com/poweredbygtc/',
  //   name: 'Facebook'
  // }
];

function Footer() {
  return (
    <>
      <nav className="w-full  text-white bg-gtc99 shadow-lg px-4 py-4 flex flex-col gap-8 justify-center items-center">
        <div className="flex flex-col md:flex-row gap-2 md:gap-10  w-full justify-center items-center">
          <div className=" flex justify-center md:justify-end items-end flex-1">
            <div className="flex flex-row md:gap-3">
              {footerRightLinks.map((link, index) => (
                <React.Fragment key={link.name}>
                  <FooterLink {...link} />
                  {index + 1 < footerRightLinks.length && <span className="font-medium text-gray-300 mx-1">|</span>}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className=" flex flex-col gap-4 justify-center order-first md:order-none mb-4 md:mb-0 w-fit">
            <img
              src="/images/logo-GTC-Landscape-White.svg"
              alt="GTC Logo"
              title="GTC Logo"
              width={300}
              height={62}
              tabIndex={0}
              className="w-48 inline-block cursor-pointer mx-auto"
            />
            <div className=" flex gap-4 justify-center">
              {socialLinks.map((data) => (
                <Link key={data.icon} target="_blank" to={data.link} className="hover:text-gray-300 " aria-label={data.name}>
                  <img src={data.icon} alt={data.name} className=" inline-block cursor-pointer" />
                </Link>
              ))}
            </div>
          </div>

          <div className="flex justify-center md:justify-start items-end flex-1">
            <div className="flex flex-row md:gap-3 items-center">
              {footerLeftLinks.map((link, index) => (
                <React.Fragment key={link.name}>
                  <FooterLink {...link} />
                  {index + 1 < footerLeftLinks.length && <span className="font-medium text-gray-300 mx-1">|</span>}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="text-center">CST 2063352-70. Fla. Seller of Travel Reg. No. ST38939</div>
      </nav>
    </>
  );
}

export default Footer;
