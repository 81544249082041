import { ReactNode } from 'react';

export interface HeroImageProps {
  imageSrc: string;
  children: ReactNode;
}

function HeroImageWithComponent({ imageSrc, children }: HeroImageProps) {
  return (
    <div className="relative mx-auto w-full h-[596px] tk-ivypresto-display -z-10 flex justify-center items-center overflow-hidden  md:">
      <div
        aria-label="Hero Image"
        role="img"
        className="h-full w-full absolute -z-10 top-2 left-0"
        style={{
          backgroundImage: `url('${imageSrc}')`,
          backgroundSize: 'cover',
          backgroundPosition: '50%'
        }}></div>
      <div className="overlay h-full w-full "></div>
      <div className="relative ">{children}</div>
    </div>
  );
}

export default HeroImageWithComponent;
