import { Helmet } from 'react-helmet';
import HeroImage from '../../components/HeroImage';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import DefaultButton from '../../components/DefaultButton';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface AboutUsService {
  img: string;
  title: string;
  desc: string;
  isOpened: boolean;
}

const data = [
  {
    title: 'Protravel',
    img: '/images/about-us/protravel.png',
    desc: 'Below the brand logo add the description : Founded in 1984, Protravel International is a full-service, dynamic travel company with an award-winning reputation. Protravel supports 24 locations throughout the United States and the United Kingdom, plus a network of hosted advisors in all corners of the world. Our expertise in destination, international faring and competitive products – coupled with our superior network of contacts in the airline, hotel, cruise and hospitality industry – makes Protravel one of the most powerful selling forces in the travel industry.'
  },
  {
    title: 'Tzell',
    img: '/images/about-us/tzell.png',
    desc: "Below the brand logo add the description : Founded in 1966, Tzell Travel Group is currently ranked first among the nation's largest corporate travel management companies, according to Business Travel News. In everything it does, Tzell Travel Group places the highest priority on both its corporate clients and its advisors. For the individual traveler, Tzell addresses value, convenience, comfort and safety, with its customized quality traveler services. For the corporation, Tzell offers a broad product line of cost containment, meeting services and comprehensive travel management programs. Tzell focuses on the needs of its advisors by providing the best work environment possible and the strongest leadership and support team. These elements combined with excellent air/hotel leisure programs create the 'safe haven' which is a hallmark of the Tzell brand."
  },
  {
    title: 'Collets',
    img: '/images/about-us/collets.png',
    desc: 'Below the Brad logo add the description :Since 1983, Colletts Travel has been a pioneer in providing luxury travel, with a personal touch. Over the years, the company has nurtured a loyal clientele of like-minded travelers, all looking for professional travel expertise and counsel to help them sate their wanderlust for stress-free luxury travel. Each of Colletts Travel’s advisors takes pride in their personal relationships, ensuring that each luxury travel experience is carefully planned and executed. Colletts Collection is the B2B division of the brand, launched in 2021, offering luxury tour operator services to Internova Travel Group businesses. Colletts Collection specializes in bespoke services, including customized itineraries, concierge services and a once in a lifetime experiences.'
  }
];

const dataServices: AboutUsService[] = [
  {
    img: '/images/about-us/Trophy.png',
    title: 'INDUSTRY LEADING TMC',
    desc: 'As a part of our agency, you align yourself with a leading TMC. Benefit from our established reputation and network, opening doors to exclusive deals and partnerships that elevate your service offerings.',
    isOpened: false
  },
  {
    img: '/images/about-us/Users.png',
    title: 'BEST-IN-CLASS DEDICATED TEAMS',
    desc: "We understand the value of personalized support. That's why we provide dedicated teams to assist you every step of the way. Whether it's itinerary planning, client inquiries, or troubleshooting, our teams are here to ensure your success.",
    isOpened: false
  },
  {
    img: '/images/about-us/Handshake.png',
    title: 'COMPREHENSIVE SUPPORT',
    desc: 'We equip you with everything you need to meet and exceed client demands. From cutting-edge technology to extensive training resources, our dedicated training department ensures that you are equipped with the latest industry knowledge and skills to provide unparalleled service to your clients.',
    isOpened: false
  },
  {
    img: '/images/about-us/UsersFour.png',
    title: 'THRIVING COMMUNITY OF AGENTS',
    desc: 'Join a community of like-minded travel advisors who share your passion for exploration and client satisfaction. Exchange insights, tips, and experiences with fellow agents, fostering a culture of collaboration and growth.',
    isOpened: false
  },
  {
    img: '/images/about-us/GearSix.png',
    title: 'ADVANCED TOOLS AND TECHNOLOGY',
    desc: 'Stay ahead of the curve with access to state-of-the-art tools and technology. Streamline your workflow, enhance efficiency, and deliver exceptional service to your clients with our innovative solutions.',
    isOpened: false
  },
  {
    img: '/images/about-us/Coins.png',
    title: 'FINANCE AND ACCOUNTING SUPPORT',
    desc: 'Say goodbye to tedious financial tasks. Our agency provides unbeatable finance and accounting support, allowing you to focus on what you do best – creating unforgettable travel experiences for your clients.',
    isOpened: false
  },
  {
    img: '/images/about-us/Icon07.png',
    title: 'COMPETITIVE COMMISSION SPLIT',
    desc: 'We believe in rewarding your hard work. Enjoy a competitive commission split that recognizes your contributions and incentivises your dedication to delivering exceptional service.',
    isOpened: false
  },
  {
    img: '/images/about-us/ClockClockwise.png',
    title: '24/7 AFTER-HOURS SUPPORT COVERAGE',
    desc: "Travel doesn't always happen during business hours. Rest easy knowing that you and your clients have access to round-the-clock support, ensuring peace of mind no matter the time zone.",
    isOpened: false
  },
  {
    img: '/images/about-us/Crown.png',
    title: 'EXCLUSIVE CLIENT PROGRAMMES',
    desc: 'Gain access to exclusive value adds through Internova hotel programmes. Offer your clients the ultimate perks and privileges, setting your services apart and enhancing their travel experiences.',
    isOpened: false
  }
];

function AboutUs() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [serviceData, setServiceData] = useState<AboutUsService[]>(dataServices);

  const onServiceSelected = (service: AboutUsService) => {
    var _new = serviceData.map((y) => {
      if (y.title === service.title) {
        return { ...y, isOpened: !service.isOpened };
      }

      return { ...y, isOpened: false };
    });

    setServiceData(_new);
  };

  const renderBox = (x: AboutUsService) => (
    <div
      key={x.title}
      onMouseEnter={() => onServiceSelected(x)}
      onMouseLeave={() => onServiceSelected(x)}
      className={[
        'flex flex-col gap-3 bg-white shadow-lg items-center py-4 px-1 opacity-100 text-center  ',
        x.isOpened ? 'h-fit' : 'h-fit',
        'w-full '
      ].join(' ')}>
      <img src={x.img} className="w-[60px]" />
      <div className="text-xl md:text-2xl ">{x.title}</div>
      <div
        className={[
          'text-base md:text-lg  text-gtc47 flex-1 px-10 overflow-hidden  transition-all delay-150 duration-300 ',
          x.isOpened ? 'max-h-[1000px] min-h-[140px]' : 'max-h-0'
        ].join(' ')}>
        {x.desc}
      </div>
      <div
        className={[
          'flex flex-col items-center cursor-pointer overflow-hidden transition-all delay-100 duration-100 ease-in-out ',
          !x.isOpened ? 'max-h-[28px]' : 'max-h-0'
        ].join(' ')}
        onClick={() => {
          onServiceSelected(x);
        }}>
        <div>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 5V27" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 18L16 27L25 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>
      <div
        className={[
          'flex flex-col items-center cursor-pointer overflow-hidden  transition-all delay-100 duration-100 ease-in-out ',
          x.isOpened ? 'max-h-[28px]' : 'max-h-0'
        ].join(' ')}
        onClick={() => {
          var _new = serviceData.map((y) => {
            if (y.title === x.title) {
              return { ...y, isOpened: false };
            }

            return { ...y };
          });

          setServiceData(_new);
        }}>
        <div>
          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 27.5V5.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 14.5L16 5.5L25 14.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Helmet>
        <meta name="title" content="About Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta
          name="description"
          content="Global Travel Collection (GTC) is the world’s largest collection of international luxury travel agencies"
        />
        <title>About Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/about-gtc"></link>
      </Helmet>
      <section id="mainsec" className="w-full -mt-20">
        <HeroImage imageSrc="/images/about-us/hero.webp" title="About Us" />
      </section>

      <section className="mb-24 flex flex-col gap-10">
        <div className=" mt-10">
          <div className=" z-10  mb-2" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
            <div className=" flex flex-col gap-4 items-center p-4 md:p-12 text-white t text-center 2xl:container mx-auto ">
              <div className="text-3xl md:text-4xl ">THE HOME OF THE INDEPENDENT TRAVEL ADVISOR</div>
              <div className=" text-base md:text-xl  text-gtcbd">
                Global Travel Collection is a collection of international luxury travel agencies, supporting over 100 advisors in the UK. We
                are a leader in global travel, known for cutting-edge solutions that provide maximum earning potential and support for our
                advisors.
                <br />
                <br />
                As part of Internova Travel Group, our family of international travel brands stand out across the travel industry… Our
                combined global reach and leverage translate into value, recognition, and preferential treatment for our world travellers.
                …and mutually beneficial, productive partnerships with valued suppliers.
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gtc14 ">
          <Swiper
            className="2xl:container mx-auto"
            spaceBetween={20}
            loop={false}
            navigation={{
              enabled: true
            }}
            pagination={true}
            modules={[Navigation, Pagination]}
            onSwiper={(a) => console.log(a)}
            // navigation={{
            //   prevEl: '.button-prev',
            //   nextEl: '.button-next'
            // }}
            breakpoints={{
              200: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: false,
                centeredSlidesBounds: false,
                navigation: {
                  enabled: false
                }
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: false,
                centeredSlidesBounds: false,
                navigation: {
                  enabled: false
                }
              },
              1024: {
                slidesPerView: 1,
                slidesPerGroupSkip: 0,
                // spaceBetween: 20,
                centeredSlides: false,
                loop: false,
                centeredSlidesBounds: false,
                navigation: {
                  enabled: true
                }
              }
            }}>
            <div className="">
              {data.map((x) => (
                <SwiperSlide key={x.title}>
                  <div className="swiper-slide py-10 px-6 md:px-40 flex flex-col gap-4 items-center ">
                    <img src={x.img} alt={x.title} />
                    <div className={['text-center min-h-[120px] py-4 flex justify-center items-center'].join(' ')}>
                      <p className=" text-base md:text-xl   text-gtcbd">{x.desc}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
        <div
          style={{
            backgroundImage: "url('/images/about-us/mountain2.png')",
            backgroundSize: 'cover'
          }}>
          <div
            className="bg-gtcF2 z-10  text-black flex flex-col gap-2 items-center py-4 md:py-6 md:mb-12 min-h-[1000px]"
            style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
            <div className=" flex flex-col gap-4 items-center py-4 md:py-12  text-center 2xl:container mx-auto ">
              <div className="text-3xl md:text-4xl ">WANT TO BECOME A GTC TRAVEL ADVISOR?</div>
              <div className="text-base md:text-xl  text-gtc47">
                GTC advisors are industry leaders and experts in providing premium travel services to leisure travellers, corporate
                executives and the entertainment industry.
              </div>
            </div>
            <div className="2xl:container mx-auto w-full md:px-6 mb-8 relative flex flex-col gap-10">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 flex-1 md:absolute top-0 w-full ">
                {serviceData.slice(0, 3).map((x) => (
                  <div key={x.title} className="z-30">
                    {renderBox(x)}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 flex-1 md:absolute top-[245px] w-full ">
                {serviceData.slice(3, 6).map((x) => (
                  <div key={x.title} className="z-20">
                    {renderBox(x)}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 flex-1 md:absolute top-[490px] w-full ">
                {serviceData.slice(6, 9).map((x) => (
                  <div key={x.title} className="z-10">
                    {renderBox(x)}
                  </div>
                ))}
              </div>
            </div>
            <DefaultButton title="JOIN US" onClick={() => navigate('/join-us')} classNames="!font-medium mb-4 md:mb-0" />
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
