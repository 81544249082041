/* eslint-disable no-unused-vars */
// import SearchBox from '../../components/SearchBox';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet';
import DefaultButton from '../../components/DefaultButton';
import HeroImageWithComponent from '../../components/HeroImageWithComponent';
import { useNavigate } from 'react-router';

const expandableImages = [
  {
    original: 'images/home/holiday/01.webp',
    name: 'Honeymoons'
  },
  {
    original: 'images/home/holiday/2.webp',
    name: 'Family Holidays'
  },

  {
    original: 'images/home/holiday/5.webp',
    name: 'All Inclusive'
  },
  {
    original: 'images/home/holiday/3.webp',
    name: 'Private Villas'
  },

  {
    original: 'images/home/holiday/4.webp',
    name: 'Adult Only'
  },
  {
    original: 'images/home/holiday/7.webp',
    name: 'Spa & Wellness'
  },
  {
    original: 'images/home/holiday/8.webp',
    name: 'City Breaks'
  },
  {
    original: 'images/home/holiday/9.webp',
    name: 'Bucket List Trips'
  },
  {
    original: 'images/home/holiday/6.webp',
    name: 'Cruise & Luxury Yacht'
  },
  {
    original: 'images/home/holiday/10.webp',
    name: 'Wildlife & Safari'
  },
  {
    original: 'images/home/holiday/11.webp',
    name: 'Beach Holidays'
  },
  {
    original: 'images/home/holiday/12.webp',
    name: 'Private Jet'
  }
];

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <meta name="title" content="International Luxury Travel Agents | Global Travel Collection" />
        <meta
          name="description"
          content="Our 1,500 GTC advisors and agencies are leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment industry."
        />
        <title>International Luxury Travel Agents | Global Travel Collection</title>
        <script src="/js/infinitescroll.js" type="text/javascript" />
      </Helmet>

      <section className="w-full -mt-20">
        <HeroImageWithComponent imageSrc="/images/home/hero.webp">
          <></>
        </HeroImageWithComponent>
      </section>
      {/* <SearchBox /> */}
      <section className=" mx-0 md:mx-16 md:mb-12 mb-4 md:mt-24 mt-4">
        <div className=" mx-auto bg-gtcF2 2xl:container flex flex-col gap-6 md:gap-8 items-center text-xl text-center">
          <div className="items-center   flex flex-col gap-2 md:gap-5 px-4 md:px-0">
            <h1 className="uppercase text-3xl md:text-4xl ">CORPORATE TRAVEL TO CURAD HOLIDAYS</h1>
            <div className="md:mb-5   text-gtc47">
              <p>
                {`We are industry leaders in providing premium travel services to leisure travellers, corporate clients, and the entertainment
              industry. With expertise spanning every sector of travel, we take pride in catering to your every need, whether it's crafting
              the journey of a lifetime, streamlining your business travel, or coordinating every element of your entertainment excursions.
             `}
              </p>
              <p>{`Anchored in the value of booking human, our global industry recognition leverages the best value, and access to exclusive VIP
              perks and amenities for our clients.`}</p>
            </div>
            <DefaultButton
              title="DISCOVER YOUR TRAVEL ADVISOR"
              onClick={() => {
                navigate('/find-an-elite-travel-agent');
              }}
            />
          </div>
          <div className="flex flex-col gap-4 md:gap-20 mt-12">
            <img src="images/home/explore_now.png" />
            <div className="px-4 ">
              <h2 className="uppercase text-3xl md:text-4xl  ">ARE YOU READY TO PLAN YOUR NEXT HOLIDAY?</h2>
              <div className="  text-gtc47">
                <p>We specialise in…</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <Swiper
            className="2xl:container mx-auto"
            spaceBetween={50}
            loop={true}
            navigation={{
              prevEl: '.button-prev-album',
              nextEl: '.button-next-album'
            }}
            modules={[Navigation]}
            breakpoints={{
              200: {
                slidesPerView: 1.1,
                spaceBetween: 20,
                centeredSlides: false,
                centeredSlidesBounds: false
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: false,
                centeredSlidesBounds: false
              },
              1024: {
                slidesPerView: 3,
                slidesPerGroupSkip: 0,
                // spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                centeredSlidesBounds: false
              }
            }}>
            <div className="">
              {expandableImages.map(({ original, name }) => (
                <SwiperSlide key={name}>
                  <div
                    key={name}
                    className="cursor-pointer relative"
                    onClick={() => navigate(`/advisor-stories?type=${encodeURIComponent(name)}`)}>
                    <img className="" src={original} />
                    <div className="text-white z-20 left-0 right-0 bottom-10 text-2xl md:text-4xl absolute uppercase text-center  px-2">
                      {name}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
        <div className="2xl:container mx-auto w-full md:flex flex-row justify-between my-10 hidden">
          <button className="bg-transparent flex justify-center items-center  focus:outline-none button-prev-album">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27 16L5 16" stroke="#999A9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 25L5 16L14 7" stroke="#999A9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <button className="bg-transparent flex justify-center items-center  focus:outline-none button-next-album">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 16L27 16" stroke="#999A9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18 25L27 16L18 7" stroke="#999A9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </section>
    </>
  );
}

export default Home;
