export interface DefaultButtonProps {
  onClick?: () => void;
  title: string;
  classNames?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

function DefaultButton({ onClick, title, classNames = '', disabled = false, type = 'button' }: DefaultButtonProps) {
  return (
    <button
      className={[
        'w-fit pt-3 pb-1 text-center text-black border-[1.5px] border-black bg-transparent px-6 hover:cursor-pointer text-lg md:text-2xl font-[Avenir-bold]',
        classNames
      ].join(' ')}
      role="button"
      aria-label={title}
      onClick={onClick}
      disabled={disabled}
      type={type}>
      {title}
    </button>
  );
}

export default DefaultButton;
