import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';

// import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import HeroImageWithComponent from '../../components/HeroImageWithComponent';

const formSubmitUrl = 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';

/* eslint-disable react/no-unescaped-entities */
function JoinUs() {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [reCaptcha, setReCaptcha] = useState<string | null>(null);
  const [desribeYou, setDescribeYou] = useState<string>('Leisure');
  const [referal, setReferal] = useState<string>('Referral');
  const [country, setCountry] = useState<string>('');
  // const [isStateRequired, setIsStateRequired] = useState<boolean>(false);
  const baseUrl = window.location.protocol + '//' + window.location.host;
  // const thankYouNew = baseUrl + '/thankyou-new-agent';
  const thankYouExperienced = baseUrl + '/thankyou-join-us';

  const onRecaptchChange = (value: any) => {
    setReCaptcha(value);
  };

  const handleSubmit = (event: any) => {
    if (reCaptcha === null) {
      event.preventDefault();
      alert('Please check the recaptcha');
      return;
    }
  };
  // const handleSubmitNew = (event: any) => {
  //   if (reCaptcha === null) {
  //     event.preventDefault();
  //     alert('Please check the recaptcha');
  //     return;
  //   }
  // };

  // useEffect(() => {
  //   setReCaptcha(null);
  //   setCountry('');
  //   setIsStateRequired(false);
  // }, [formType]);

  // useEffect(() => {
  //   if (country === 'United States') {
  //     // Make state required
  //     setIsStateRequired(true);
  //   } else {
  //     setIsStateRequired(false);
  //   }
  // }, [country]);

  // useEffect(() => {
  //   const parsed = queryString.parse(searchParams.toString());
  //   if (parsed?.lead) {
  //     if (parsed.lead.toString().toLowerCase() === 'itke') {
  //       setLeadSource('intheknowexperiences.com');
  //     } else if (parsed.lead.toString().toLowerCase() === 'tzell') {
  //       setLeadSource('tzell.com');
  //     } else if (parsed.lead.toString().toLowerCase() === 'protravel') {
  //       setLeadSource('protravelinc.com');
  //     }
  //   }
  // }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Join Us Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/join-us"></link>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <section className="w-full">
        <HeroImageWithComponent imageSrc="/images/join-us/hero.webp">
          <div className="flex flex-col items-center md:gap-6 gap-4">
            <h1 className="text-[40px] sm:text-[56px] font-light text-center text-white">Join Us</h1>
            <div className=" text-xl md:text-3xl text-gtce0 text-center font-light">
              Join one of the world’s largest collections of international luxury travel agencies.
            </div>
          </div>
        </HeroImageWithComponent>
      </section>
      <article className="px-4 py-10 mx-auto  2xl:container">
        <section className="flex justify-center flex-col mt-4 gap-10 md:mx-20">
          <>
            <div className="text-4xl uppercase leading-[50px]  text-center font-semibold">
              COMPLETE THE BRIEF FORM BELOW AND OUR TEAM <br /> WILL CONTACT YOU SHORTLY
            </div>
            <form
              action={formSubmitUrl}
              method="POST"
              id="experiencedForm"
              onSubmit={handleSubmit}
              className="w-full justify-center flex flex-col gap-10 sf-form">
              <input type="hidden" name="oid" value="00D300000000U26" />
              <input type="hidden" name="retURL" value={thankYouExperienced} />
              <input type="hidden" name="recordType" id="recordType" value="0128b000000paDY" />
              <input type="hidden" name="lead_source" id="lead_source" value="globaltravelcollection.co.uk" />

              <div className="flex flex-col md:flex-row gap-10 justify-center ">
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="first_name">
                      <span className="text-red-500">*</span>First Name
                    </label>
                    <input
                      id="first_name"
                      maxLength={40}
                      name="first_name"
                      size={20}
                      type="text"
                      pattern="^[A-Z][a-zA-Z\-\. ]+$"
                      title="Please enter only alphabets and starting with uppercase"
                      required
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Which best describes you?
                    </div>
                    <select
                      id="00N8b00000HSvk5"
                      name="00N8b00000HSvk5"
                      title="Which best describes you?"
                      required
                      onChange={(x) => setDescribeYou(x.target.value)}>
                      <option value="Leisure">Leisure</option>
                      <option value="Entertainment">Entertainment</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  {desribeYou === 'Other' && (
                    <div className="flex flex-col gap-1">
                      <div>Other Description</div>
                      <textarea
                        id="00N8b00000JKFu1"
                        name="00N8b00000JKFu1"
                        rows={3}
                        //type="text"
                        wrap="soft"></textarea>
                    </div>
                  )}
                  {desribeYou === 'Leisure' && (
                    <div className="flex flex-col gap-1">
                      <div>
                        <span className="text-red-500">*</span>Do you have experience of dynamically packaging your own itineraries?
                      </div>
                      <textarea
                        id="00N8b00000JKFtz"
                        name="00N8b00000JKFtz"
                        rows={3}
                        required
                        //type="text"
                        wrap="soft"></textarea>
                    </div>
                  )}
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Describe your business
                    </div>
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      //type="text"
                      wrap="soft"></textarea>
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="phone">
                      <span className="text-red-500">*</span>Phone Number
                    </label>
                    <input
                      id="phone"
                      type="tel"
                      pattern="^(?:\+?1\s*(?:[.-]\s*)?)?(?:\([2-9]\d{2}\)|[2-9]\d{2})\s*(?:[.-]\s*)?\d{3}\s*(?:[.-]\s*)?\d{4}$"
                      //onInput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      title="Please enter proper phone number"
                      maxLength={30}
                      name="phone"
                      size={20}
                      required
                    />
                    <span>'+' not needed</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Country
                    </div>
                    <select
                      id="Country__c"
                      name="Country__c"
                      title="Country"
                      className="experiencedCountry"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required>
                      <option value="">--None--</option>
                      <option value="AFG">Afghanistan</option>
                      <option value="ALA">Aland Islands</option>
                      <option value="DZA">Algeria</option>
                      <option value="AND">Andorra</option>
                      <option value="AGO">Angola</option>
                      <option value="AIA">Anguilla</option>
                      <option value="ATA">Antarctica</option>
                      <option value="ATG">Antigua and Barbuda</option>
                      <option value="ARG">Argentina</option>
                      <option value="ARM">Armenia</option>
                      <option value="ABW">Aruba</option>
                      <option value="AUS">Australia</option>
                      <option value="AUT">Austria</option>
                      <option value="AZE">Azerbaijan</option>
                      <option value="BHS">Bahamas</option>
                      <option value="BHR">Bahrain</option>
                      <option value="Bali">Bali</option>
                      <option value="BGD">Bangladesh</option>
                      <option value="BRB">Barbados</option>
                      <option value="BLR">Belarus</option>
                      <option value="BEL">Belgium</option>
                      <option value="BLZ">Belize</option>
                      <option value="BEN">Benin</option>
                      <option value="BMU">Bermuda</option>
                      <option value="BTN">Bhutan</option>
                      <option value="BOL">Bolivia</option>
                      <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                      <option value="BIH">Bosnia and Herzegovina</option>
                      <option value="BWA">Botswana</option>
                      <option value="BVT">Bouvet Island</option>
                      <option value="BRA">Brazil</option>
                      <option value="IOT">British Indian Ocean Territory</option>
                      <option value="BRN">Brunei Darussalam</option>
                      <option value="BGR">Bulgaria</option>
                      <option value="BFA">Burkina Faso</option>
                      <option value="BDI">Burundi</option>
                      <option value="KHM">Cambodia</option>
                      <option value="CMR">Cameroon</option>
                      <option value="CAN">Canada</option>
                      <option value="CPV">Cape Verde</option>
                      <option value="CYM">Cayman Islands</option>
                      <option value="CAF">Central African Republic</option>
                      <option value="TCD">Chad</option>
                      <option value="CHL">Chile</option>
                      <option value="CHN">China</option>
                      <option value="CXR">Christmas Island</option>
                      <option value="CCK">Cocos (Keeling) Islands</option>
                      <option value="COL">Colombia</option>
                      <option value="COM">Comoros</option>
                      <option value="COD">Congo, the Democratic Republic of the</option>
                      <option value="COK">Cook Islands</option>
                      <option value="CRI">Costa Rica</option>
                      <option value="HRV">Croatia</option>
                      <option value="CUB">Cuba</option>
                      <option value="CUW">Curaçao</option>
                      <option value="CYP">Cyprus</option>
                      <option value="CZE">Czech Republic</option>
                      <option value="DNK">Denmark</option>
                      <option value="DJI">Djibouti</option>
                      <option value="DMA">Dominica</option>
                      <option value="DOM">Dominican Republic</option>
                      <option value="ECU">Ecuador</option>
                      <option value="EGY">Egypt</option>
                      <option value="SLV">El Salvador</option>
                      <option value="ENG">England</option>
                      <option value="GNQ">Equatorial Guinea</option>
                      <option value="ERI">Eritrea</option>
                      <option value="EST">Estonia</option>
                      <option value="ETH">Ethiopia</option>
                      <option value="FLK">Falkland Islands (Malvinas)</option>
                      <option value="FRO">Faroe Islands</option>
                      <option value="FJI">Fiji</option>
                      <option value="FIN">Finland</option>
                      <option value="FRA">France</option>
                      <option value="GUF">French Guiana</option>
                      <option value="PYF">French Polynesia</option>
                      <option value="ATF">French Southern Territories</option>
                      <option value="GAB">Gabon</option>
                      <option value="GMB">Gambia</option>
                      <option value="GEO">Georgia</option>
                      <option value="DEU">Germany</option>
                      <option value="GHA">Ghana</option>
                      <option value="GIB">Gibraltar</option>
                      <option value="GRC">Greece</option>
                      <option value="GRL">Greenland</option>
                      <option value="GRD">Grenada</option>
                      <option value="GLP">Guadeloupe</option>
                      <option value="GTM">Guatemala</option>
                      <option value="GGY">Guernsey</option>
                      <option value="GIN">Guinea</option>
                      <option value="GNB">Guinea-Bissau</option>
                      <option value="GUY">Guyana</option>
                      <option value="HTI">Haiti</option>
                      <option value="HMD">Heard Island and McDonald Islands</option>
                      <option value="VAT">Holy See (Vatican City State)</option>
                      <option value="HND">Honduras</option>
                      <option value="HKG">Hong Kong</option>
                      <option value="HUN">Hungary</option>
                      <option value="ISL">Iceland</option>
                      <option value="IND">India</option>
                      <option value="IDN">Indonesia</option>
                      <option value="IRN">Iran, Islamic Republic of</option>
                      <option value="IRQ">Iraq</option>
                      <option value="IRL">Ireland</option>
                      <option value="IMN">Isle of Man</option>
                      <option value="ISR">Israel</option>
                      <option value="ITA">Italy</option>
                      <option value="JAM">Jamaica</option>
                      <option value="JPN">Japan</option>
                      <option value="JEY">Jersey</option>
                      <option value="JOR">Jordan</option>
                      <option value="KAZ">Kazakhstan</option>
                      <option value="KEN">Kenya</option>
                      <option value="KIR">Kiribati</option>
                      <option value="PRK">Korea, Democratic People’s Republic of</option>
                      <option value="KOR">Korea, Republic of</option>
                      <option value="KWT">Kuwait</option>
                      <option value="KGZ">Kyrgyzstan</option>
                      <option value="LAO">Lao People’s Democratic Republic</option>
                      <option value="LVA">Latvia</option>
                      <option value="LBN">Lebanon</option>
                      <option value="LSO">Lesotho</option>
                      <option value="LBR">Liberia</option>
                      <option value="LBY">Libya</option>
                      <option value="LIE">Liechtenstein</option>
                      <option value="LTU">Lithuania</option>
                      <option value="LUX">Luxembourg</option>
                      <option value="MAC">Macao</option>
                      <option value="MKD">Macedonia, the former Yugoslav Republic of</option>
                      <option value="MDG">Madagascar</option>
                      <option value="MWI">Malawi</option>
                      <option value="MYS">Malaysia</option>
                      <option value="MDV">Maldives</option>
                      <option value="MLI">Mali</option>
                      <option value="MLT">Malta</option>
                      <option value="MTQ">Martinique</option>
                      <option value="MRT">Mauritania</option>
                      <option value="MUS">Mauritius</option>
                      <option value="MYT">Mayotte</option>
                      <option value="MEX">Mexico</option>
                      <option value="MDA">Moldova, Republic of</option>
                      <option value="MCO">Monaco</option>
                      <option value="MNG">Mongolia</option>
                      <option value="MNE">Montenegro</option>
                      <option value="MSR">Montserrat</option>
                      <option value="MAR">Morocco</option>
                      <option value="MOZ">Mozambique</option>
                      <option value="MMR">Myanmar</option>
                      <option value="NAM">Namibia</option>
                      <option value="NRU">Nauru</option>
                      <option value="NPL">Nepal</option>
                      <option value="NLD">Netherlands</option>
                      <option value="NCL">New Caledonia</option>
                      <option value="NZL">New Zealand</option>
                      <option value="NIC">Nicaragua</option>
                      <option value="NER">Niger</option>
                      <option value="NGA">Nigeria</option>
                      <option value="NIU">Niue</option>
                      <option value="NFK">Norfolk Island</option>
                      <option value="NOR">Norway</option>
                      <option value="OMN">Oman</option>
                      <option value="PAK">Pakistan</option>
                      <option value="PSE">Palestinian Territory, Occupied</option>
                      <option value="PAN">Panama</option>
                      <option value="PNG">Papua New Guinea</option>
                      <option value="PRY">Paraguay</option>
                      <option value="PER">Peru</option>
                      <option value="PHL">Philippines</option>
                      <option value="PCN">Pitcairn</option>
                      <option value="POL">Poland</option>
                      <option value="PRT">Portugal</option>
                      <option value="PRI">Puerto Rico</option>
                      <option value="QAT">Qatar</option>
                      <option value="REU">Reunion</option>
                      <option value="ROU">Romania</option>
                      <option value="RUS">Russian Federation</option>
                      <option value="RWA">Rwanda</option>
                      <option value="BLM">Saint Barthelemy</option>
                      <option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value="KNA">Saint Kitts and Nevis</option>
                      <option value="LCA">Saint Lucia</option>
                      <option value="MAF">Saint Martin (French part)</option>
                      <option value="SPM">Saint Pierre and Miquelon</option>
                      <option value="VCT">Saint Vincent and the Grenadines</option>
                      <option value="WSM">Samoa</option>
                      <option value="SMR">San Marino</option>
                      <option value="STP">Sao Tome and Principe</option>
                      <option value="SAU">Saudi Arabia</option>
                      <option value="SCT">Scotland</option>
                      <option value="SEN">Senegal</option>
                      <option value="SRB">Serbia</option>
                      <option value="SYC">Seychelles</option>
                      <option value="SLE">Sierra Leone</option>
                      <option value="SGP">Singapore</option>
                      <option value="SXM">Sint Maarten (Dutch part)</option>
                      <option value="SVK">Slovakia</option>
                      <option value="SVN">Slovenia</option>
                      <option value="SLB">Solomon Islands</option>
                      <option value="SOM">Somalia</option>
                      <option value="ZAF">South Africa</option>
                      <option value="SGS">South Georgia and the South Sandwich Islands</option>
                      <option value="SSD">South Sudan</option>
                      <option value="ESP">Spain</option>
                      <option value="LKA">Sri Lanka</option>
                      <option value="SDN">Sudan</option>
                      <option value="SUR">Suriname</option>
                      <option value="SJM">Svalbard and Jan Mayen</option>
                      <option value="SWE">Sweden</option>
                      <option value="CHE">Switzerland</option>
                      <option value="SYR">Syrian Arab Republic</option>
                      <option value="TWN">Taiwan, Province of China</option>
                      <option value="TJK">Tajikistan</option>
                      <option value="TZA">Tanzania, United Republic of</option>
                      <option value="THA">Thailand</option>
                      <option value="TLS">Timor-Leste</option>
                      <option value="TGO">Togo</option>
                      <option value="TKL">Tokelau</option>
                      <option value="TON">Tonga</option>
                      <option value="TTO">Trinidad and Tobago</option>
                      <option value="TUN">Tunisia</option>
                      <option value="TUR">Turkey</option>
                      <option value="TKM">Turkmenistan</option>
                      <option value="TCA">Turks and Caicos Islands</option>
                      <option value="TUV">Tuvalu</option>
                      <option value="UGA">Uganda</option>
                      <option value="UKR">Ukraine</option>
                      <option value="ARE">United Arab Emirates</option>
                      <option value="GBR">United Kingdom</option>
                      <option value="USA">United States</option>
                      <option value="URY">Uruguay</option>
                      <option value="UZB">Uzbekistan</option>
                      <option value="VUT">Vanuatu</option>
                      <option value="VEN">Venezuela, Bolivarian Republic of</option>
                      <option value="VNM">Viet Nam</option>
                      <option value="VGB">Virgin Islands, British</option>
                      <option value="WLS">Wales</option>
                      <option value="WLF">Wallis and Futuna</option>
                      <option value="ESH">Western Sahara</option>
                      <option value="YEM">Yemen</option>
                      <option value="ZMB">Zambia</option>
                      <option value="ZWE">Zimbabwe</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="city">
                      <span className="text-red-500">*</span>City
                    </label>
                    <input id="city" maxLength={50} name="city" size={20} required />
                  </div>

                  {/* <div className="flex flex-col gap-1">
                    <div>
                      {isStateRequired && <span className="text-red-500 experiencedStateLabel">*</span>}
                      State
                    </div>
                    <select
                      id="00N80000004xYsV"
                      name="00N80000004xYsV"
                      title="Address State"
                      className="experiencedState"
                      required={isStateRequired}
                      disabled={!isStateRequired}>
                      <option value="">--None--</option>
                      <option value="ALABAMA">ALABAMA</option>
                      <option value="ALASKA">ALASKA</option>
                      <option value="ALBERTA">ALBERTA</option>
                      <option value="ARIZONA">ARIZONA</option>
                      <option value="ARKANSAS">ARKANSAS</option>
                      <option value="BRITISH COLUMBIA">BRITISH COLUMBIA</option>
                      <option value="CALIFORNIA">CALIFORNIA</option>
                      <option value="COLORADO">COLORADO</option>
                      <option value="CONNECTICUT">CONNECTICUT</option>
                      <option value="DELAWARE">DELAWARE</option>
                      <option value="DISTRICT OF COLUMBIA">DISTRICT OF COLUMBIA</option>
                      <option value="FLORIDA">FLORIDA</option>
                      <option value="GEORGIA">GEORGIA</option>
                      <option value="HAWAII">HAWAII</option>
                      <option value="IDAHO">IDAHO</option>
                      <option value="ILLINOIS">ILLINOIS</option>
                      <option value="INDIANA">INDIANA</option>
                      <option value="IOWA">IOWA</option>
                      <option value="KANSAS">KANSAS</option>
                      <option value="KENTUCKY">KENTUCKY</option>
                      <option value="LOUISIANA">LOUISIANA</option>
                      <option value="MAINE">MAINE</option>
                      <option value="MANITOBA">MANITOBA</option>
                      <option value="MARYLAND">MARYLAND</option>
                      <option value="MASSACHUSETTS">MASSACHUSETTS</option>
                      <option value="MICHIGAN">MICHIGAN</option>
                      <option value="MINNESOTA">MINNESOTA</option>
                      <option value="MISSISSIPPI">MISSISSIPPI</option>
                      <option value="MISSOURI">MISSOURI</option>
                      <option value="MONTANA">MONTANA</option>
                      <option value="NEBRASKA">NEBRASKA</option>
                      <option value="NEVADA">NEVADA</option>
                      <option value="NEW BRUNSWICK">NEW BRUNSWICK</option>
                      <option value="NEW HAMPSHIRE">NEW HAMPSHIRE</option>
                      <option value="NEW JERSEY">NEW JERSEY</option>
                      <option value="NEW MEXICO">NEW MEXICO</option>
                      <option value="NEW YORK">NEW YORK</option>
                      <option value="NEWFOUNDLAND and LABRADOR">NEWFOUNDLAND and LABRADOR</option>
                      <option value="NORTH CAROLINA">NORTH CAROLINA</option>
                      <option value="NORTH DAKOTA">NORTH DAKOTA</option>
                      <option value="NORTHWEST TERRITORIES">NORTHWEST TERRITORIES</option>
                      <option value="NOVA SCOTIA">NOVA SCOTIA</option>
                      <option value="OHIO">OHIO</option>
                      <option value="OKLAHOMA">OKLAHOMA</option>
                      <option value="ONTARIO">ONTARIO</option>
                      <option value="OREGON">OREGON</option>
                      <option value="PALAU">PALAU</option>
                      <option value="PENNSYLVANIA">PENNSYLVANIA</option>
                      <option value="PRINCE EDWARD ISLAND">PRINCE EDWARD ISLAND</option>
                      <option value="PUERTO RICO">PUERTO RICO</option>
                      <option value="QUEBEC">QUEBEC</option>
                      <option value="RHODE ISLAND">RHODE ISLAND</option>
                      <option value="SASKATCHEWAN">SASKATCHEWAN</option>
                      <option value="SOUTH CAROLINA">SOUTH CAROLINA</option>
                      <option value="SOUTH DAKOTA">SOUTH DAKOTA</option>
                      <option value="TENNESSEE">TENNESSEE</option>
                      <option value="TEXAS">TEXAS</option>
                      <option value="UTAH">UTAH</option>
                      <option value="VERMONT">VERMONT</option>
                      <option value="VIRGINIA">VIRGINIA</option>
                      <option value="VIRGIN ISLANDS">VIRGIN ISLANDS</option>
                      <option value="WASHINGTON">WASHINGTON</option>
                      <option value="WEST VIRGINIA">WEST VIRGINIA</option>
                      <option value="WISCONSIN">WISCONSIN</option>
                      <option value="WYOMING">WYOMING</option>
                      <option value="YUKON">YUKON</option>
                    </select>
                  </div> */}

                  <div className="flex flex-col gap-1">
                    <label htmlFor="zip">
                      <span className="text-red-500">*</span>Post Code
                    </label>
                    <input type="number" id="zip" maxLength={5} name="zip" required />
                  </div>
                </div>
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="last_name">
                      <span className="text-red-500">*</span>Last Name
                    </label>
                    <input
                      id="last_name"
                      maxLength={80}
                      name="last_name"
                      size={20}
                      pattern="^[A-Z][a-zA-Z\-\. ]+$"
                      type="text"
                      title="Please enter only alphabets and starting with uppercase"
                      required
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="revenue">
                      <span className="text-red-500">*</span>Estimated Annual Revenue
                    </label>
                    <input id="revenue" name="revenue" size={20} type="number" required />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>GDS Experience
                    </div>
                    <select id="00N8b00000JKFu2" name="00N8b00000JKFu2" title="Specify which GDS Provider do you use:" required>
                      <option value="">---</option>
                      <option value="Amadeus">Amadeus</option>
                      <option value="Sabre">Sabre</option>
                      <option value="Galileo">Galileo</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="email">
                      <span className="text-red-500">*</span>Email Address
                    </label>
                    <input id="email" maxLength={80} name="email" size={20} type="email" required />
                  </div>

                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>What is the best time to reach you?
                    </div>
                    <select id="00N8b00000HSuFQ" name="00N8b00000HSuFQ" title="What is the best time to reach you?" required>
                      <option value="">--None--</option>
                      <option value="Anytime">Anytime</option>
                      <option value="Morning">Morning</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Evening">Evening</option>
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>What is your decision timeframe?
                    </div>
                    <select id="00N8b00000HSuFZ" name="00N8b00000HSuFZ" title="What is your decision timeframe?" required>
                      <option value="">--None--</option>
                      <option value="Immediately">Immediately</option>
                      <option value="Just looking">Just looking</option>
                      <option value="Next Few Weeks">Next Few Weeks</option>
                      <option value="1-2 Months">1-2 Months</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>Why do you want to join Global Travel Collection?
                    </div>
                    <textarea
                      id="00N8b00000JKFu3"
                      name="00N8b00000JKFu3"
                      rows={3}
                      //type="text"
                      wrap="soft"></textarea>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div>
                      <span className="text-red-500">*</span>How did you hear about us?
                    </div>
                    <select
                      id="00N8b00000JKFu0"
                      name="00N8b00000JKFu0"
                      title="How did you hear about us?:"
                      required
                      onChange={(x) => setReferal(x.target.value)}>
                      <option value="Referral">Referral</option>
                      <option value="Search Engine">Search Engine</option>
                      <option value="Globaltravelcollection.co.uk">Globaltravelcollection.co.uk</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="zip">Please Specify if Other:</label>
                    <input type="text" id="00N8b00000HSuFX" maxLength={150} name="00N8b00000HSuFX" size={20} required />
                  </div>
                  {referal === 'Referral' && (
                    <div className="flex flex-col gap-1">
                      <label htmlFor="zip">Name of Referral:</label>
                      <input type="text" id="00N34000005S0jF" maxLength={100} name="00N34000005S0jF" size={20} required />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-center">
                <ReCAPTCHA sitekey="6LcYiA0lAAAAAPi397xVpvupIrsMGs6FCIL3L9Bc" onChange={onRecaptchChange} />
              </div>
              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  className="w-auto py-2 font-normal text-center text-white bg-black border border-black hover:font-semibold px-7 hover:cursor-pointer">
                  {' '}
                  Get in Touch
                </button>
              </div>
            </form>
          </>
          <section className="mx-2 md:mx-10" id="privacy">
            *Global Travel Collection takes your security very seriously. Please review our{' '}
            <a href="https://internova.com/privacy/" className="font-bold cursor-pointer">
              Privacy Policy
            </a>{' '}
            to learn more about how we protect you and your data. By submitting this information, you are agreeing to receive periodic
            e-mails from Global Travel Collection related to our luxury travel services from which you can unsubscribe at any time.
          </section>
        </section>
      </article>
    </>
  );
}

export default JoinUs;
