import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { EmailUtilityViewModel, OptionType } from '../../helpers/types';
import APIService from '../../services/services';
import HeroImage from '../../components/HeroImage';
import DefaultButton from '../../components/DefaultButton';
import Select from 'react-select';

const emailTopics = ['Media Inquiries', 'Technical Issues', 'Become a GTC Independent Advisor (IC)', 'Other'];

export const customEmailSelectStyles = {
  control: (provided: any) => ({
    ...provided,

    borderRadius: '0px !important',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '5px',
    height: '45px !important',
    minHeight: '45px !important'
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '0px'
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '0px !important'
  }),
  option: (provided: any) => ({
    ...provided,
    borderBottom: '#CCCDCD 1px solid',
    paddingTop: '10px',
    paddingBottom: '10px'
  }),

  groupHeading: (provided: any) => ({
    ...provided,
    fontWeight: 'bold !important',
    fontSize: '14px',
    color: '#000',
    borderBottom: '#000 1px solid'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    marginLeft: '5px'
  })
};
const locations = [
  {
    img: '/images/contact-us/london.png',
    title: 'London',
    desc: 'Headquarters',
    address1: '+44 207 959 4233',
    address2: '101 St. Martins Lane, First Floor WC2N 4AZ',
    address3: 'hello@globaltravelcollection.com'
  },
  {
    img: '/images/contact-us/newyork.png',
    title: 'New York',
    desc: 'Headquarters',
    address1: '(212) 944-2121',
    address2: '1633 Broadway floor 35 & 36 New York, NY 10019',
    address3: 'hello@globaltravelcollection.com'
  },
  {
    img: '/images/contact-us/beverly.png',
    title: 'Beverly Hills',
    desc: 'Headquarters',
    address1: '(310) 271-9566',
    address2: '345 North Maple Drive, Suite 250, CA 90210',
    address3: 'hello@globaltravelcollection.com'
  }
];

function ContactUs() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [isProcessing, setIsProcessing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<EmailUtilityViewModel>(
    //@ts-ignore
    {
      fullName: '',
      fromEmail: '',
      comments: '',
      toEmail: ''
    }
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log(data);
    setIsProcessing(true);
    APIService()
      .gtcService.sendEmailUtility(data)
      .then(() => {
        setIsProcessing(false);
        navigate('/thank-you-contact-us');
      });
    //navigate('/thank-you-contact-us');
  };
  const optionRenderer = (option: OptionType) => {
    return <div className=" -mx-3 px-3">{option.value}</div>;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <Helmet>
        <meta name="title" content="Contact Us Global Travel Collection and Our Luxury Travel Agents | GTC" />
        <meta
          name="description"
          content="For media inquiries, technical issues with the website, or becoming a GTC Independent Advisors (IC), please contact us using the form. For travel inquiries, please contact one of our agents directly."
        />
        <title>Contact Us Global Travel Collection and Our Luxury Travel Agents | GTC</title>
        <link rel="canonical" href="/contact-us"></link>
      </Helmet>
      <section id="mainsec" className="w-full -mt-20">
        <HeroImage imageSrc="/images/contact-us/hero.webp" title="Contact Us" />
      </section>
      <section className="2xl:container mx-auto flex flex-col md:flex-row gap-3 py-[50px] md:px-12 px-4  text-gtc60">
        <form className="flex md:w-[430px] flex-col gap-4 md:mr-20 contact-form" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Your name</div>
            <input
              id="Name"
              className="border border-gtccc"
              maxLength={80}
              name="name"
              type="text"
              required
              aria-label="Name"
              aria-required={true}
              onChange={(event) => {
                data.fullName = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Your email</div>
            <input
              id="Email"
              className="border border-gtccc"
              maxLength={80}
              name="email"
              type="email"
              aria-label="Email"
              aria-required={true}
              required
              onChange={(event) => {
                data.fromEmail = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Email Topic</div>
            <Select
              styles={customEmailSelectStyles}
              className=" w-full"
              options={emailTopics.map((x) => {
                return {
                  value: x,
                  label: x
                };
              })}
              required
              aria-required={true}
              formatOptionLabel={optionRenderer}
              components={{
                DropdownIndicator: () => (
                  <div className="mr-2">
                    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 4.83333L5.49986 1.5L9.99971 4.83333" stroke="#47484C" strokeWidth="1.42857" />
                      <path d="M10 8.16667L5.50014 11.5L1.00029 8.16667" stroke="#47484C" strokeWidth="1.42857" />
                    </svg>
                  </div>
                )
              }}
              onChange={(val) => {
                if (val !== null) {
                  data.toEmail = val.value;
                  setData({ ...data });
                }
              }}
              placeholder="Select Email Topic"
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base md:text-xl">Your message</div>
            <textarea
              id="yourMessage"
              className="border border-gtccc"
              name="yourMessage"
              aria-label="Your message"
              aria-required={true}
              required
              rows={3}
              onChange={(event) => {
                data.comments = event.target.value;
                setData({ ...data });
              }}
              //type="text"
              wrap="soft"></textarea>
          </div>
          <div className="flex-1 flex flex-col gap-6 items-center my-10">
            <DefaultButton
              type="submit"
              disabled={isProcessing}
              title={isProcessing ? `Sending email...` : `Send email`}
              classNames="!font-medium"
            />
          </div>
        </form>
        <div className="flex-1 flex flex-col gap-10  text-black">
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl font-semibold">CONNECT WITH GTC</div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl font-semibold">BECOME A GTC TRAVEL ADVISOR</div>
            <div className="text-lg md:text-2xl   text-gtc47">
              <a href="/join-us" className="font-bold underline">
                Contact our Sales Team
              </a>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl font-semibold">HOLIDAY INQUIRIES</div>
            <div className="text-lg md:text-2xl  text-gtc47">
              We would love the opportunity to share our knowledge and expertise with you on your next journey. We are passionate about
              helping you travel better with us,{' '}
              <a className="font-bold underline" href="/find-an-elite-travel-agent">
                please use our agent locator.
              </a>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl font-semibold">BUSINESS TRAVEL INQUIRIES</div>
            <div className="text-lg md:text-2xl  text-gtc47">
              Contact our Sales Team
              <br />
              <a href="mailto:SalesUK@globaltravelcollection.com" className="font-bold underline">
                SalesUK@globaltravelcollection.com
              </a>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl font-semibold">PRESS OFFICE</div>
            <div className="text-lg md:text-2xl  text-gtc47">
              Kirsty Chan – Senior PR & Communications Executive
              <br />
              <a href="mailto:Kirsty.chan@globaltravelcollection.com" className="font-bold underline">
                Kirsty.chan@globaltravelcollection.com
              </a>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            <div className="text-xl md:text-3xl font-semibold uppercase">Looking for a career at GTC?</div>
            <div className="text-lg md:text-2xl  text-gtc47">
              We are proud to offer career opportunities in a progressive and diverse work environment, we’re always on the lookout for new
              talent.
            </div>
            <div className="text-lg md:text-2xl  text-gtc47">
              If you’re passionate about travel and interested in joining one of the world’s leading travel management companies, take a
              look at our current vacancies below.
            </div>
            <div className="text-lg md:text-2xl  text-gtc47">
              If you can’t find the perfect role for you at the moment, you can send us your CV at{' '}
              <a href="mailto:UK-HR@globaltravelcollection.com" className="font-bold underline">
                UK-HR@globaltravelcollection.com
              </a>
              . We will keep your details on file should a suitable role arise in the near future.
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:container mx-auto md:px-12 px-0 mt-6">
        <div className=" flex flex-col md:flex-row gap-10  mb-10">
          {locations.map((x) => (
            <div className="w-full" key={x.title}>
              <img src={x.img} alt={x.title} className="w-full h-[450px]  object-cover" />
              <div
                className={[
                  'text-center min-h-[80px] flex flex-col w-full gap-1 justify-center items-center -mt-[85px] bg-gtc47 opacity-90 py-5'
                ].join(' ')}>
                <div className=" text-white text-[30px] uppercase ">{x.title}</div>
              </div>
              <div className="flex flex-col gap-2 items-center text-center justify-center bg-white py-12 px-2  md:px-24 mt-4">
                <div className="text-lg text-gtc60 flex flex-row gap-1 items-center   text-center">
                  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-12">
                    <path
                      d="M9.89453 12.675C10.7306 14.4015 12.1268 15.7941 13.8555 16.6258C13.983 16.6861 14.124 16.7123 14.2647 16.7016C14.4054 16.6909 14.5409 16.6438 14.6578 16.5648L17.1969 14.8687C17.3091 14.7926 17.4387 14.7462 17.5737 14.7338C17.7086 14.7213 17.8446 14.7433 17.9688 14.7976L22.7219 16.839C22.8843 16.9066 23.02 17.0258 23.1081 17.1781C23.1962 17.3304 23.2318 17.5075 23.2094 17.682C23.0587 18.8578 22.4848 19.9385 21.595 20.7217C20.7052 21.505 19.5604 21.9372 18.375 21.9375C14.7117 21.9375 11.1984 20.4822 8.60809 17.8919C6.01774 15.3015 4.5625 11.7883 4.5625 8.12497C4.56277 6.93953 4.99497 5.7948 5.77823 4.90499C6.56149 4.01517 7.64214 3.44125 8.81797 3.2906C8.99249 3.26818 9.16955 3.30375 9.32187 3.39184C9.47419 3.47993 9.59332 3.61565 9.66094 3.7781L11.7023 8.54138C11.7554 8.66361 11.7776 8.79702 11.767 8.92985C11.7564 9.06268 11.7132 9.19086 11.6414 9.3031L9.94531 11.8828C9.8698 11.9995 9.82548 12.1336 9.81659 12.2723C9.8077 12.411 9.83454 12.5496 9.89453 12.675V12.675Z"
                      stroke="#606062"
                      strokeWidth="1.625"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1">{x.address1}</span>
                </div>
                <div className="text-lg text-gtc60 flex flex-row ">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-12">
                    <path
                      d="M13 13.8125C14.7949 13.8125 16.25 12.3574 16.25 10.5625C16.25 8.76757 14.7949 7.3125 13 7.3125C11.2051 7.3125 9.75 8.76757 9.75 10.5625C9.75 12.3574 11.2051 13.8125 13 13.8125Z"
                      stroke="#606062"
                      strokeWidth="1.625"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.125 10.5625C21.125 17.875 13 23.5625 13 23.5625C13 23.5625 4.875 17.875 4.875 10.5625C4.875 8.40762 5.73102 6.34099 7.25476 4.81726C8.77849 3.29352 10.8451 2.4375 13 2.4375C15.1549 2.4375 17.2215 3.29352 18.7452 4.81726C20.269 6.34099 21.125 8.40762 21.125 10.5625V10.5625Z"
                      stroke="#606062"
                      strokeWidth="1.625"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>{x.address2}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
