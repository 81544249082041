import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
// import { getMoreReferralData } from '../../helpers/functions';
import { AgentViewModel } from '../../helpers/types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import APIService from '../../services/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { removeUTMCookies } from '../../helpers/functions';
import { Helmet } from 'react-helmet';

interface AppointmentFormProps {
  agent: AgentViewModel;
}

function AppointmentForm({ agent }: AppointmentFormProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  //const history = useNavigate();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [availableTimes, setAvailableTimes] = useState([
    {
      title: ''
    },
    {
      title: 'Morning',
      isAvailable: true
    },
    {
      title: 'Afternoon',
      isAvailable: true
    },
    {
      title: 'Evening',
      isAvailable: true
    }
  ]);
  // eslint-disable-next-line no-unused-vars
  const [agentBio, setAgentBio] = useState<AgentViewModel>();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getAgent = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchAgent({
        agentId: parseInt(agent.id as string)
      })
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setAgentBio(res.data.agent);
        }
      });
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
    trigger,
    setValue
  } = useForm();
  const onSubmit = (data: any) => {
    setIsProcessing(true);

    data.comments = videoHtmlTag(moment(selectedDate).format('MMMM d, YYYY'), data.preferredTime, data.comment);

    if (!isProcessing) {
      console.log(data);
      setIsProcessing(true);
      APIService()
        .gtcService.sendAgentEmailLead(data)
        .then((response) => {
          setIsProcessing(false);
          if (response.responseStatus === 1) {
            removeUTMCookies();
            navigate(
              `/travel-agent/email-agent/thank-you?AgentId=${agent.id}&FullName=${encodeURIComponent(data.firstName + ' ' + data.lastName)}`
            );
            setIsProcessing(false);
          } else {
            toast(response.error.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              progress: undefined,
              theme: 'light',
              type: 'error'
            });
          }
        })
        .catch(() => {
          setIsProcessing(false);
        });
    }
  };

  const videoHtmlTag = (date: any, time: any, comment: any) => `--VIDEO APPOINTMENT--</br>
  DATE REQUESTED: <strong> + ${date} + </strong></br>
  PREFERRED TIME: <strong> + ${time} + </strong></br>
  -------------------</br>
  ${comment}`;

  useEffect(() => {
    getAgent();
    setValue('agentId', agent.id.toString());
    setValue('referralUrl', window.location.href);
    if (selectedDate) {
      setValue('appointmentDate', selectedDate);
    }
  }, []);

  useEffect(() => {
    if (agentBio) {
      if (agentBio.agentAppointmentAvailabilty.length > 0 && selectedDate) {
        const data = agentBio.agentAppointmentAvailabilty.find((x) => x.dayOfWeek === selectedDate.day());
        setAvailableTimes([
          {
            title: 'Morning',
            isAvailable: data.morning
          },
          {
            title: 'Afternoon',
            isAvailable: data.afternoon
          },
          {
            title: 'Evening',
            isAvailable: data.evening
          }
        ]);
      }
    }
  }, [selectedDate, agentBio]);

  return (
    <>
      <Helmet>
        <script src="/js/ga_social_tracking.js" type="text/javascript" />
      </Helmet>
      <div className="p-4 flex flex-col gap-6 max-w-2xl">
        <div className="text-lg font-semibold">Request a Video Appointment with {`${agent.firstName} ${agent.lastName}`}</div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="flex flex-col">
            <input className="hidden" {...register('agentId', { required: true })} />
            <input className="hidden" {...register('referralUrl', { required: true })} />
            <div>
              <label>
                Please provide your preferred video appointment date. Your agent will be in touch with you to confirm your date and time, or
                schedule an alternative time if necessary.
              </label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            {agentBio && (
              <div>
                <ReactDatePicker
                  placeholderText="Date of appointment"
                  autoComplete="off"
                  className="border border-gray-400  py-2 px-4 w-full"
                  // name="appointmentDate"
                  {...register('appointmentDate', { required: true })}
                  selected={selectedDate ? selectedDate.toDate() : null}
                  onChange={(date) => {
                    if (date) {
                      setSelectedDate(moment(date));
                      setValue('appointmentDate', moment(date));
                      setValue('preferredTime', '');
                      trigger('appointmentDate');
                    }
                  }}
                  filterDate={(date) => agentBio?.agentAppointmentAvailabilty.some((x) => x.dayOfWeek === date.getDay())}
                  minDate={moment().toDate()}
                  // excludeDates={[new Date(), subDays(new Date(), 1)]}
                />
              </div>
            )}
            <div>{errors.appointmentDate && <span className="text-red-500 text-sm">Date of appointment is required</span>}</div>
          </div>

          <div className="flex flex-col">
            <input className="hidden" {...register('agentId', { required: true })} />
            <div>
              <label>Preferred Time to meet</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <select
                disabled={!selectedDate}
                {...register('preferredTime', { required: true })}
                className="border border-gray-400  py-2 px-4 w-full">
                <option value="">Select preferred time</option>
                {availableTimes
                  .filter((x) => x.isAvailable)
                  .map((x) => (
                    <option key={x.title} value={x.title}>
                      {x.title}
                    </option>
                  ))}
              </select>
            </div>
            <div>{errors.preferredTime && <span className="text-red-500 text-sm">Preferred Time to meet is required</span>}</div>
          </div>
          <div className="flex flex-col flex-1">
            <input className="hidden" {...register('agentId', { required: true })} />
            <div>
              <label>First Name</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="First Name"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('firstName', { required: true })}
              />
            </div>
            <div>{errors.firstName && <span className="text-red-500 text-sm">First name is required</span>}</div>
          </div>

          <div className="flex flex-col flex-1">
            <div>
              <label>Last Name</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="Last Name"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('lastName', { required: true })}
              />
            </div>
            <div>{errors.lastName && <span className="text-red-500 text-sm">Last name is required</span>}</div>
          </div>
          <div className="flex flex-col flex-1">
            <div>
              <label>Email Address</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="Email Address"
                type="email"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('emailAddress', { required: true })}
              />
            </div>
            <div>{errors.email && <span className="text-red-500 text-sm">Email is required</span>}</div>
          </div>

          <div className="flex flex-col flex-1">
            <div>
              <label>Phone Number</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input placeholder="Phone number" className="border border-gray-400  py-2 px-4 w-full" {...register('phoneNumber')} />
            </div>
            {errors.phone && <span className="text-red-500 text-sm">Phone number is required</span>}
          </div>
          <div className="flex flex-col">
            <div>
              <label>Your questions / comments to this agent</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <textarea
                placeholder="Comment"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('comments', { required: true })}
              />
              {errors.comments && <span className="text-red-500 text-sm">Question/Comment is required</span>}
            </div>
          </div>

          <div>
            <button
              className="py-2 font-normal text-center text-white bg-black border border-black
                hover:font-semibold px-7 hover:cursor-pointer  w-full text-sm"
              disabled={isProcessing}
              type="submit">
              {isProcessing ? 'Requesting video appointment ....' : 'Request Video Appointment'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

AppointmentForm.propTypes = {
  agent: PropTypes.any.isRequired
};
export default AppointmentForm;
