/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { getMoreReferralData, removeUTMCookies } from '../../helpers/functions';
import { AgentViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

interface EmailFormProps {
  agent: AgentViewModel;
}

function EmailForm({ agent }: EmailFormProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();
  const onSubmit = (data: any) => {
    if (!isProcessing) {
      console.log(data);
      setIsProcessing(true);
      APIService()
        .gtcService.sendAgentEmailLead(data)
        .then((response) => {
          setIsProcessing(false);
          removeUTMCookies();
          navigate(
            `/travel-agent/email-agent/thank-you?AgentId=${agent.id}&FullName=${
              encodeURIComponent(data.firstName + ' ' + data.lastName) + `&check=${response.data}`
            }`
          );
          setIsProcessing(false);
        })
        .catch(() => {
          setIsProcessing(false);
        });
    }
  };

  let referralUrl = window.location.href;
  if (!referralUrl.toLowerCase().includes('utm_')) {
    referralUrl = window.location.href + getMoreReferralData();
  }

  useEffect(() => {
    setValue('agentId', agent.id.toString());
    setValue('referralUrl', referralUrl);
  }, []);

  return (
    <>
      <Helmet>
        <script src="/js/ga_social_tracking.js" type="text/javascript" />
      </Helmet>
      <div className="p-4 flex flex-col gap-6 max-w-2xl">
        <div className="text-sm md:text-lg font-semibold">Sending a message to {`${agent.firstName} ${agent.lastName}`}</div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="flex flex-col">
            <input className="hidden" {...register('agentId', { required: true })} />
            <input className="hidden" {...register('referralUrl', { required: true })} />
            <div>
              <label className="text-sm md:text-base font-bold">First Name</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="First Name"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('firstName', { required: true })}
              />
            </div>
            <div>{errors.firstName && <span className="text-red-500 md:text-sm text-xs">First name is required</span>}</div>
          </div>

          <div className="flex flex-col">
            <div>
              <label className="text-sm md:text-base font-bold">Last Name</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="Last Name"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('lastName', { required: true })}
              />
            </div>
            <div>{errors.lastName && <span className="text-red-500 md:text-sm text-xs">Last name is required</span>}</div>
          </div>

          <div className="flex flex-col">
            <div>
              <label className="text-sm md:text-base font-bold">Email Address</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="Email Address"
                type="email"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('emailAddress', { required: true })}
              />
            </div>
            <div>{errors.emailAddress && <span className="text-red-500 md:text-sm text-xs">Email is required</span>}</div>
          </div>

          <div className="flex flex-col">
            <div>
              <label className="text-sm md:text-base font-bold">Phone Number</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <input
                placeholder="Phone number"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('phoneNumber', {
                  required: true,
                  maxLength: {
                    value: 20,
                    message: 'Please provide a valid phone number'
                  },
                  minLength: {
                    value: 7,
                    message: 'Please provide a valid phone number'
                  }
                })}
              />
            </div>
            {errors?.phoneNumber?.message !== undefined && (
              <span className="text-red-500 md:text-sm text-xs">
                <> {errors?.phoneNumber?.message}</>
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <div>
              <label className="text-sm md:text-base font-bold">Your questions / comments to this agent</label>
              <span className="text-red-500 md:text-sm text-xs">*</span>
            </div>
            <div>
              <textarea
                placeholder="Comment"
                className="border border-gray-400  py-2 px-4 w-full"
                {...register('comments', { required: true })}
              />
              {errors.comments && <span className="text-red-500 md:text-sm text-xs">Question/Comment is required</span>}
            </div>
          </div>

          <div>
            <button
              className="py-2 font-normal text-center text-white bg-black border border-black
                hover:font-semibold px-7 hover:cursor-pointer  w-full text-sm"
              disabled={isProcessing}
              type="submit"
              aria-label={`Email ${agent.firstName} ${agent.lastName}`}>
              {isProcessing ? `Sending email to ${agent.firstName} ${agent.lastName} ....` : `Email ${agent.firstName} ${agent.lastName}`}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

EmailForm.propTypes = {
  agent: PropTypes.any.isRequired
};
export default EmailForm;
