export interface HeroImageProps {
  imageSrc: string;
  title: string;
}

function HeroImage({ imageSrc, title }: HeroImageProps) {
  return (
    <div
      aria-label="Hero Image"
      role="img"
      className="h-[596px] justify-center px-4 items-center flex"
      style={{
        backgroundImage: `url('${imageSrc}')`,
        backgroundSize: 'cover',
        backgroundPosition: '50%'
      }}>
      <div className="flex flex-col items-center ">
        <div>
          <h1 className="text-[30px] md:text-[50px] font-light text-center text-white tk-ivypresto-display">{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default HeroImage;
