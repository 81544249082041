/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { useContext, useEffect, useRef, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { customDestinationSelectStyles, filterConfig } from '../helpers/constants';
import { AppContext } from '../context/AppContext';
import { useSearchParams } from 'react-router-dom';

type OptionType = {
  value: string;
  label: string;
};

type DestinationExpertProps = {
  callback: (destination: string) => void;
};

function DestinationExpert({ callback }: DestinationExpertProps) {
  const ref = useRef<any>(null);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const appState = useContext(AppContext);
  const [selected, setSelected] = useState<any>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | null>(null);

  const groupedSpecialities = () => {
    const items = [];

    items.push({
      label: '',
      options: appState.destinations?.map((x) => ({
        value: x.destinationId,
        label: x.destination,
        type: 'Destinations'
      }))
    });
    return items;
  };

  const optionRenderer = (option: OptionType) => {
    if (currentValue === '' || currentValue === null) return option.label;
    const regex = new RegExp(currentValue, 'gi');
    let output = option.label.replace(regex, `<b>$&</b>`);
    return <div dangerouslySetInnerHTML={{ __html: output }} />;
  };

  useEffect(() => {
    setSelected(undefined);
    setCurrentValue(null);
    ref.current?.clearValue();
  }, [searchParams]);

  useEffect(() => {
    if (selected) {
      callback(selected.label);
    }
  }, [selected]);

  return (
    <div>
      <Select
        ref={ref}
        styles={customDestinationSelectStyles}
        className=" w-full text-base z-50 text-black"
        options={groupedSpecialities()}
        components={{ DropdownIndicator: () => null }}
        onChange={(val) => {
          setSelected(val);
          setIsMenuOpen(false);
        }}
        onInputChange={(val) => {
          setCurrentValue(val);
          if (val !== '') {
            setIsMenuOpen(true);
          } else setIsMenuOpen(false);
        }}
        placeholder="Destination"
        value={selected}
        filterOption={createFilter(filterConfig)}
        menuIsOpen={isMenuOpen}
        noOptionsMessage={({ inputValue }) => !inputValue || 'Sorry, no matching results'}
        formatOptionLabel={optionRenderer}
        isClearable
        aria-label="Destination"
      />
    </div>
  );
}

export default DestinationExpert;
