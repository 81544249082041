import { Helmet } from 'react-helmet';
import HeroImage from '../../components/HeroImage';
import { useState } from 'react';

interface OurServiceProps {
  title: string;
  desc: string;
  img: string;
  bgPostionLess: string;
  viewMore: boolean;
  bgPostionMore: string;
  full: string;
}

const data: OurServiceProps[] = [
  {
    title: 'BUSINESS TRAVEL',
    desc: 'Our mission is to transform the typical stress associated with business travel into a pleasurable journey tailored to the unique needs of each individual.',
    img: '/images/our-service/BusinessTravel.webp',
    full: 'Our mission is to transform the typical stress associated with business travel into a pleasurable journey tailored to the unique needs of each individual. While others may focus on automation, we stand apart, prioritizing exceptional, personalized services.\n\nOur dedication to ensuring you experience more is ingrained in the very foundation of our business. In addition to comprehensive travel management services, we pride ourselves on offering a range of invaluable services to our corporate clients. These include VIP amenities and upgrades, 24/7 support, Concierge Assistance and Traveler Tracking.\n\n We are committed to ensuring your trips run smoother and remain cost-efficient, allowing you to focus on your core objectives while we handle the rest.',
    viewMore: false,
    bgPostionLess: 'bg-top',
    bgPostionMore: 'bg-top'
  },
  {
    title: 'Music & Touring',
    desc: 'At GTC, we redefine the experience of music touring travel management. Going above and beyond, from facilitating seamless transport to coordinating the complexities of global stadium tours.',
    img: '/images/our-service/Music.webp',
    full: 'At GTC, we redefine the experience of music touring travel management. Going above and beyond, from facilitating seamless transport to coordinating the complexities of global stadium tours. Our dedicated Travel Specialists have an unwavering commitment to turning the seemingly impossible into reality.\n\nWe are your trusted partner in navigating the intricate world of music touring travel. We promise complete discretion and professionalism for our clients, giving you peace of mind for your travel needs.\n\nWe extend an array of exclusive entertainment rates and benefits to our clients that set us apart from the rest. You can benefit from preferred rates with access to a portfolio of truly exclusive properties, VIP experiences and top-class amenities.',
    viewMore: false,
    bgPostionLess: 'bg-center',
    bgPostionMore: 'bg-top'
  },
  {
    title: 'FILM & PRODUCTION',
    desc: 'With a commitment to excellence and a profound understanding of the intricacies of production travel management, GTC delivers unparalleled services tailored to the distinctive requirements of…',
    img: '/images/our-service/Film.webp',
    full: 'With a commitment to excellence and a profound understanding of the intricacies of production travel management, GTC delivers unparalleled services tailored to the distinctive requirements of each production.\n\nLeveraging our extensive industry expertise and worldwide network, we stand as a reliable partner for both production companies and independent studios. Our dedicated approach ensures 24/7 support, 365 days a year, with personalized service that aligns seamlessly with your unique production needs.\n\nTrust in our global reach, expertise, and hands-on approach to not only meet but exceed your expectations.',
    viewMore: false,
    bgPostionLess: 'bg-top',
    bgPostionMore: 'bg-top'
  },
  {
    title: 'LUXURY HOLIDAYS',
    desc: 'We ensure that luxury hotels, resorts, villas and tour partners provide you with unique amenities and VIP experiences. We go beyond conventional luxury, offering unparalleled…',
    img: '/images/our-service/Luxury.webp',
    full: 'We ensure that luxury hotels, resorts, villas and tour partners provide you with unique amenities and VIP experiences. We go beyond conventional luxury, offering unparalleled experiences tailored to your individual preferences and desires. We specialize in crafting personalized escapes for discerning individuals seeking more than just a holiday.\n\n Our commitment to excellence is reflected in our dedicated team of travel experts who, armed with extensive knowledge and a passion for creating unforgettable moments, ensure a seamless journey from initial planning to realization.\n\nFrom exotic destinations like the Maldives to adventurous expeditions to the Antarctic, elevate your travel itinerary by booking with the most knowledgeable travel professionals in the industry. Your bespoke itinerary is meticulously curated, encompassing every detail to guarantee an extraordinary and uniquely tailored experience.',
    viewMore: false,
    bgPostionLess: 'bg-center',
    bgPostionMore: 'bg-top'
  }
];

function OurServices() {
  const [services, setServices] = useState<OurServiceProps[]>(data);
  const header = () => <Helmet></Helmet>;

  const onSelected = (d: any, open: boolean) => {
    var _new = data.map((y) => {
      if (y.title === d.title) {
        return { ...y, viewMore: open };
      }

      return { ...y, viewMore: false };
    });

    setServices(_new);
  };

  return (
    <>
      {header}
      <section id="mainsec" className="w-full -mt-20">
        <HeroImage imageSrc="/images/hero/hero_service.webp" title="Our Services" />
      </section>
      <section id="content" className=" py-6  mx-auto">
        <div className="flex-1 flex flex-col gap-10">
          <div className="text-center 2xl:container mx-auto md:px-20 px-4   text-gtc47">
            We provide premium travel services to leisure travellers, corporate executives and the entertainment industry. Global Travel
            Collection’s in-house team are dedicated to providing comprehensive support for our independent travel advisors.
            <br />
            <br />
            Our advisors are well-travelled and draw from their own real-life travel experiences when it comes to travel planning.
          </div>
          {services.map((x) => (
            <div
              key={x.title}
              className={['md:px-20 py-12 bg-cover', !x.viewMore ? x.bgPostionLess : x.bgPostionMore].join(' ')}
              style={{
                backgroundImage: `url(${x.img})`
              }}>
              <div className="bg-gtcF2 bg-opacity-80 flex flex-col items-center gap-6  p-6 md:p-10 mx-auto text-center md:w-[680px] md:min-h-[300px]  transition-all duration-200 ease-in-out">
                <div className="text-3xl md:text-4xl uppercase  ">{x.title}</div>
                <div
                  className={[
                    'text-lg md:text-xl whitespace-pre-line  text-gtc47  overflow-hidden cursor-pointer  transition-all delay-50 duration-300  ease-in-out ',

                    x.viewMore ? 'max-h-[1000px]' : 'max-h-[120px] clipped-text'
                  ].join(' ')}
                  onMouseLeave={() => onSelected(x, false)}
                  onMouseEnter={() => onSelected(x, true)}>
                  {x.viewMore ? x.full : x.desc}
                </div>
                {!x.viewMore && (
                  <svg
                    className="cursor-pointer"
                    onClick={() => onSelected(x, true)}
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 5.5V27.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 18.5L16.5 27.5L25.5 18.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
                {x.viewMore && (
                  <svg
                    className="cursor-pointer"
                    onClick={() => onSelected(x, false)}
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 27.5V5.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 14.5L16 5.5L25 14.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default OurServices;
