/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet';
import HeroImageWithComponent from '../../components/HeroImageWithComponent';
import DefaultButton from '../../components/DefaultButton';

interface LeadershipProps {
  name: string;
  position: string;
  desc?: string;
  img: string;
  isOpened: boolean;
  full: string;
}
import { useState } from 'react';

const data: LeadershipProps[] = [
  {
    name: 'Jason Oshiokpekhai',
    img: '/images/leadership/JO',
    full: `With over 18 years of international experience in tech and aviation sectors including stints at Delta, American Airlines and Internova Travel Group, Jason is a seasoned business executive. He directed the rebranding of Travel Leaders UK to GTC UK and managed a strategic restructuring to cut costs, ensuring high-quality service to GTC UK's diverse clientele during and post-pandemic.

    Born in New York City, Jason studied Business Management at Boston University and pursued a Master’s degree in Political Science from St. John’s University, Rome.
   
    Regarded as a travel industry thought leader, Jason is actively involved with the Business Travel Association and often speaks at industry events. He has participated in the TTG Luxury Travel Summit 2021, judged for the TTG Luxury Travel Awards 2021, and served as a panelist at World Travel Market London 2022 and Aspire’s State of Luxury Travel event. He's also lectured at the New York University Stern School of Business on travel and hospitality.
   
   `,
    isOpened: false,
    position: 'Senior Vice President, Advisor Success & Managing Director'
  },
  {
    name: 'Francesca Mendola',
    img: '/images/leadership/FM',
    full: `Prior to joining the company, Francesca was a hotelier for luxury hotels across London specializing in private dining, events, corporate and leisure markets. Francesca was poached by Global Travel Collection and joined as Business Development Manager in 2015. During her 9 years at GTC, Francesca has been promoted twice, to Global Account Manager, Director of Account Management and then to her current title as Vice President. Francesca now oversees the day-to-day management of the business in addition to a team of seven, representing Corporate Account Management, Advisor Success, Business Development, Supplier Relations and PR & Marketing. Francesca’s team is dedicated to supporting business growth and retention for GTC Advisors across entertainment, leisure and corporate travel markets.

    Most recently, Francesca has been recognized for her accomplishments as ‘Rising Star’ in The Business Travel People’s Awards 2023. This award reflects her aptitude and aspirations to become a business leader of the future.`,
    isOpened: false,
    position: 'Vice President'
  },
  {
    name: 'Ade Taiwo',
    img: '/images/leadership/AT',
    full: `Ade oversees financial operations for the network Internova Travel Group’s UK brands namely, ALTOUR, Global Travel Collection and Your Event Solutions.

    He holds over 27 years’ experience in the travel industry and wide-ranging experience in Finance, Human Resources, and IT. A trained Accountant, he started his travel industry career in 1995 at The Appointment Group London UK. Whilst there, he grew within the business from an Accountant of one of its subsidiaries to Group Finance Director with director responsibilities overseeing Finance, Human Resources and IT functions. In this role, he was involved in the strategic planning and implementing the grow plans of the business worldwide. He was responsible for the establishment of the US (New York & Los Angeles) and Australia (Melbourne & Sydney) Companies and was also the lead on all the acquisitions made by the company.
   
    He left The Appointment Group after twenty years and joined Global Travel Collection as Finance Director, subsequently taking on responsibilities across Internova Travel Group in the UK.
   
    Ade holds a BA in Accounting & Finance from the University of Greenwich and sits as the Finance Huddle Chair for the Business Travel Association (BTA).`,
    isOpened: false,
    position: 'Finance Director'
  },
  {
    name: 'Francesca Vinchiaturo',
    img: '/images/leadership/FV',
    full: `Francesca is responsible for the Airlines Commercial Relations for Internova Travel Group in the UK, as well as managing the Revenue Management Team for Global Travel Collection UK, which encompasses renowned brands such as Protravel, Tzell, Colletts Travel, and Scotia Travel.

    Francesca oversees air commercial agreements within the UK for the various brands within Internova Travel Group, and she plays a pivotal role in cultivating strong relationships with airlines.
   
   Additionally, with a focus on innovation and technology, Francesca specializes in developing dynamic and creative strategies to maximiser revenue, leveraging technology tools and innovative techniques.
   
   Her dedicated team serves a diverse community of luxury leisure, corporate, and entertainment travel advisors. Their efforts involve identifying revenue-maximizing opportunities, implementing cost-saving measures, and ensuring adherence to contractual obligations.
   
    Drawing from more than 17 years of extensive industry experience across all markets, Francesca's expertise includes Airlines commercial programs and operations, Air Fares, booking and technology platforms. Her deep industry insights are the result of working with airlines, as well as working with prominent corporate travel agencies like Chambers Travel, CTM, Hillgate Travel, and Reed & Mackay.
   
    Committed to supporting and promoting gender equality and diversity within the travel industry, Francesca is an active advocate. She serves as a member of the GTC's Diversity & Inclusion Committee and is a member of the Aviation Strategy Group for the Business Travel Association (BTA).`,
    isOpened: false,
    position: 'Director of Airline Relations & Revenue Management EMEA, Internova Travel Group '
  },
  {
    name: 'Folusho Alade',
    img: '/images/leadership/FA',
    full: `Folusho has over fifteen years of valuable experience in designing, implementing, and monitoring project performance to ensure company success. He oversees all IT functions across Internova Travel Group brands in the UK across Global Travel Collection, ALTOUR, and Your Event Solutions.

    Previously at Business Travel Management Limited, Folusho worked as a Systems Analyst, providing solutions to the business strategy for front, mid and back-office operations. He eventually headed up the IT team, looking after security, automation and ultimately yields in ROI and profitability. He left Business Travel Management Limited after nine years to join GTC UK in March, 2022.
    
     Folusho has a strong focus on Information and Cyber Security for dynamic environments towards achievement of security of assets in all forms.`,
    isOpened: false,
    position: 'IT MANAGER'
  },
  {
    name: 'Alexandra Habuc',
    img: '/images/leadership/AH',
    full: `Alexandra holds 14 years of experience working in Human Resources, and previously held roles in the healthcare industry. She has a strong academic background with a degree in Psychology from Dublin Business School.

    Having joined Global Travel Collection in 2017 as HR Manager, Alexandra was promoted to HR Director in 2021. Now, she oversees all HR activities including recruitment, employee training, and policy development in collaboration with the senior management team. Combining a deep understanding of human resources practices and employment laws with organization and communication management, Alexandra ensures the success of internal teams and continues to drive equality and diversity in the recruitment process.`,
    isOpened: false,
    position: 'HR Director'
  },
  {
    name: 'LORENA VIRGILI',
    img: '/images/leadership/LV',
    full: `Lorena brings 3 decades of experience working in the travel industry and 14 years of dedicated experience specializing in operations management. Her journey with Global Travel Collection began in 2018 when she joined as an Assistant Operations Manager. Since then, she has since been promoted to Operations Manager, and most recently, Director of Operations.

    Borne out of client demand, Lorena grew the Operations department from a team of 4 to 13, which now covers all areas of support for Ticketing, Fares, Product Training, Implementation and Project Management.
   
    Today, the team is responsible for implementing tools, services, and best practices aimed at optimizing operational efficiency and driving business growth in line with overarching company objectives. Projects are executed with a focus on innovation, continuous improvement, and the delivery of unparalleled service standards.`,
    isOpened: false,
    position: 'Director of Operations'
  }
];

function Leadership() {
  const [leaderships, setLeaderships] = useState<LeadershipProps[]>(data);
  const header = () => <Helmet></Helmet>;

  const onSelected = (service: LeadershipProps, open: boolean) => {
    var _new = data.map((y) => {
      if (y.name === service.name) {
        return { ...y, isOpened: open };
      }

      return { ...y, isOpened: false };
    });

    setLeaderships(_new);
  };

  return (
    <>
      {header}
      <section id="mainsec" className="w-full -mt-20">
        <HeroImageWithComponent imageSrc="/images/leadership/hero.webp">
          <div className="flex flex-col items-center md:gap-6 gap-4">
            <h1 className="text-[40px] sm:text-[56px] font-light text-center text-white">Leadership</h1>
            <div className=" text-xl md:text-[40px] text-gtce0 ">Meet Our Leadership Team</div>
          </div>
        </HeroImageWithComponent>
      </section>
      <section id="content" className=" md:py-6  mx-auto 2xl:container">
        {leaderships.map((x) => (
          <div key={x.name} className="flex flex-col md:flex-row  md:mx-10 my-6">
            <div className="relative">
              <img className="md:w-[401px] md:h-[400px] object-cover object-top hidden-tw md:block" src={x.img + '.png'} />
              <img className="w-[390px] h-[390px] object-cover object-top md:hidden" src={x.img + '-m.png'} />
              <div className="flex flex-col md:hidden absolute bottom-0 text-left bg-white px-2  opacity-75 w-full pt-6 pb-1">
                <div className="text-xl md:text-3xl uppercase ">{x.name}</div>
                <div className="text-xl md:text-3xl  uppercase text-gtc47">{x.position}</div>
              </div>
            </div>

            <div
              className={[
                'bg-white flex flex-col md:-ml-20 opacity-75 gap-2 md:gap-4 md:px-14 px-2 text-left pb-6 pt-2  md:py-8 flex-1 md:mt-12 '
              ].join(' ')}>
              <div className="md:flex flex-col gap-4 hidden-tw">
                <div className="text-xl md:text-3xl uppercase ">{x.name}</div>
                <div className="text-xl md:text-3xl uppercase text-gtc47 px-1">{x.position}</div>
              </div>
              <div
                className={[
                  'text-xl text-left text-gtc47 overflow-hidden cursor-pointer  transition-all delay-50 duration-300  ease-in-out    whitespace-pre-line  ',
                  x.isOpened ? 'max-h-[1000px]' : 'max-h-[120px] clipped-text'
                ].join(' ')}
                onMouseLeave={() => onSelected(x, false)}
                onMouseEnter={() => onSelected(x, true)}>
                {x.full}
              </div>
              <div className="mt-4 text-center self-center">
                {!x.isOpened && (
                  <svg
                    className="cursor-pointer"
                    onClick={() => {
                      onSelected(x, true);
                    }}
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 5.5V27.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 18.5L16.5 27.5L25.5 18.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
                {x.isOpened && (
                  <svg
                    className="cursor-pointer"
                    onClick={() => {
                      onSelected(x, false);
                    }}
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 27.5V5.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 14.5L16 5.5L25 14.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
}

export default Leadership;
