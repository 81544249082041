/* eslint-disable no-unused-vars */
import { useRef, useState } from 'react';
import { AgentDetailViewModel, MediaEntity } from '../../../helpers/types';
import 'react-image-gallery/styles/css/image-gallery.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Modal from 'react-modal';
// Import Swiper styles
import 'swiper/css';
import { modalGalleryCustomStyles } from '../../../helpers/constants';
import ReactImageGallery from 'react-image-gallery';

interface ItineraryProps {
  data: AgentDetailViewModel;
  albumTitle?: string;
  title?: string;
}

function AgentAlbum({ data, title = 'Photo and Videos' }: ItineraryProps) {
  const medias = data.media || [];
  const [selectedAlbumId, setSelectedAlbumId] = useState<number | null>();
  const [modalOpen, setModalOpen] = useState<'Album' | null>(null);

  const getVideo = (media: MediaEntity) => {
    var videoLink = `<iframe src="https://player.vimeo.com/video/${media.itemId}" style="width: 100%;"
      class="w-full object-cover h-[200px]" frameborder="0" title="${media.title}" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

    return (
      <div className={[cardWidth, 'flex flex-col items-center gap-2'].join(' ')}>
        <div className="album" dangerouslySetInnerHTML={{ __html: videoLink }}></div>
        <div className="text-sm font-bold text-left ml-4">{media.title}</div>
      </div>
    );
  };
  const cardWidth = medias.length < 3 ? 'w-full md:w-full' : 'w-full';
  const cardWrapper = () => {
    switch (medias.length) {
      case 3:
        return 'md:px-0  md:mr-0';
      case 2:
        return '';
      case 1:
        return '';

      default:
        return '';
    }
  };
  return (
    <>
      {medias.length > 0 && (
        <div className=" border-gtc3 border-b pb-4 relative">
          <h2 className="font-bold text-2xl mt-0 mb-2">{title}</h2>
          {medias.length > 3 && (
            <>
              <div className="absolute inset-y-0 left-0 z-10 hidden-tw md:flex items-center button-prev-album">
                <button className="bg-transparent  -ml-8 flex justify-center items-center  focus:outline-none">
                  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="30" height="48" viewBox="0 0 30 48" fill="none">
                    <rect width="30" height="48" fill="#C0C0C0" />
                    <g id="Profile - 1440 px" clipPath="url(#clip0_1072_24882)">
                      <rect width="1440" height="4017" transform="translate(-404 -1119)" fill="#f2f2f2" />
                      <g id="Group 1788">
                        <g id="Group 1787">
                          <g id="Arrows">
                            <path
                              id="Vector"
                              d="M30 5.64L11.4575 24L30 42.36L24.2915 48L2.08066e-06 24L24.2915 -5.03246e-07L30 5.64Z"
                              fill="#D9D9D9"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_24882">
                        <rect width="1440" height="4017" fill="#f2f2f2" transform="translate(-404 -1119)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className="absolute inset-y-0 right-0 z-10 hidden-tw md:flex items-center button-next-album">
                <button className="bg-transparent -mr-2 lg:-mr-8 flex justify-center items-center  focus:outline-none">
                  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="30" height="48" viewBox="0 0 30 48" fill="none">
                    <path
                      id="Vector"
                      d="M6.19703e-08 42.36L18.5425 24L8.251e-09 5.64L5.7085 0L30 24L5.7085 48L6.19703e-08 42.36Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}
          <div className={['relative  flex-row px-0 ', cardWrapper(), medias.length > 3 ? 'lg:mx-10' : 'md:mx-auto '].join(' ')}>
            <Swiper
              spaceBetween={20}
              slidesPerView={1.5}
              loop={false}
              slidesPerGroupSkip={1}
              modules={[Navigation]}
              navigation={{
                prevEl: '.button-prev-album',
                nextEl: '.button-next-album'
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  centeredSlidesBounds: false
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  centeredSlidesBounds: false
                },
                1024: {
                  slidesPerView: 3,
                  // spaceBetween: 20,
                  centeredSlides: false,
                  centeredSlidesBounds: false
                }
              }}>
              <div className="px-20">
                {medias.map((x, index) => (
                  <>
                    <SwiperSlide key={x.itemId}>
                      <div className="swiper-slide p-1 w-full">
                        <div
                          className="flex flex-col justify-center items-center gap-1  w-full  text-center cursor-pointer"
                          key={x.itemId}
                          onClick={() => {
                            setSelectedAlbumId(x.itemId);
                            setModalOpen('Album');
                          }}
                          role="button"
                          tabIndex={0}>
                          {x.itemType === 1 && (
                            <div>
                              <div className="hover:opacity-40 ease-in-out duration-500">
                                <img className={[cardWidth, 'h-[200px] object-cover'].join(' ')} src={x.image} alt={x.imageCaption} />
                              </div>
                              <div className={[cardWidth, 'text-center py-4 flex flex-col justify-center items-center'].join(' ')}>
                                <div className="text-sm font-thin">{x.imageCaption}</div>
                                <div className="text-sm font-bold text-left">{x.title}</div>{' '}
                              </div>
                            </div>
                          )}
                          {x.itemType === 2 && <>{getVideo(x)}</>}
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      )}
      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalGalleryCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times fa-xl text-white hover:text-gray-600"></i>
          </div>
          <div className="text-white text-xl font-bold  text-center mb-6">
            {data.media?.find((x) => x.itemId === selectedAlbumId)?.title}
          </div>
          <div className="mx-20 px-40 bg-black py-2">
            {/* <div className="text-black text-xs  font-thin  text-center mb-6 absolute top-16 right-20">
              Photo 1 of {data.media?.find((x) => x.itemId === selectedAlbumId)?.gallery.length}
            </div> */}
            <ReactImageGallery
              items={
                data.media
                  ?.find((x) => x.itemId === selectedAlbumId)
                  ?.gallery.map((y) => ({
                    original: y.imageUrl,
                    thumbnail: y.thumbUrl,
                    originalTitle: y.caption,
                    originalWidth: 100
                  })) || []
              }
              showFullscreenButton={false}
              showPlayButton={false}
              indexSeparator=" of "
              showIndex={true}
              renderItem={(x: any) => (
                <div className="flex flex-col justify-center items-center gap-1  w-full text-center cursor-pointer  mb-10">
                  <div className="">
                    <img src={x.original} alt={x.originalTitle} />
                  </div>
                  <div className="text-xl mt-2 font-bold text-left text-white">{x.originalTitle}</div>
                </div>
              )}
              additionalClass="max-w-xs md:max-w-4xl"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AgentAlbum;
