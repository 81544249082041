import { Link, useLocation, useNavigate } from 'react-router-dom';
import HeaderLink, { HeaderLinkProps } from '../../components/HeaderLink';
import React, { useEffect, useState } from 'react';

const headerLinks: HeaderLinkProps[] = [
  // {
  //   name: 'Find An Advisor',
  //   to: 'find-an-elite-travel-agent',
  //   group: 0
  // },
  {
    name: 'Home',
    to: '',
    group: 1
  },
  {
    name: 'About Us',
    to: 'about-gtc',
    group: 1,
    child: {
      name: 'Leadership',
      to: 'leadership-team',
      group: 1
    }
  },
  {
    name: 'Join Us',
    to: '/join-us',
    group: 1
  },
  {
    name: 'Our Services',
    to: 'our-services',
    group: 1
  },
  {
    name: 'Inspiration',
    to: 'advisor-inspiration',
    group: 1
  },

  {
    name: 'Contact Us',
    to: 'contact-us',
    group: 1
  }
  // {
  //   name: 'Advisor Offers',
  //   to: 'advisor-offers',
  //   group: 1
  // }
];

function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>('/images/main-logo-white.svg');
  const [menuColor, setMenuColor] = useState<string>('#434447');

  useEffect(() => {
    if (
      pathname === '/about-gtc' ||
      pathname === '/' ||
      pathname.includes('advisor-inspiration/story/') ||
      pathname.includes('travel-agent/')
    ) {
      setLogo('/images/main-logo-black.svg');
      setMenuColor('#434447');
    } else {
      setLogo('/images/main-logo-white.svg');
      setMenuColor('#ffffff');
    }
    setShowMenu(false);
  }, [pathname]);
  return (
    <>
      <nav className="w-full  text-white bg-transparent py-2  relative">
        <div className="md:px-12  py-1 mx-auto flex flex-row justify-between p-2 px-4">
          <Link className="flex items-center" to="/">
            <img
              src={logo}
              alt="GTC LOGO"
              title="GTC LOGO"
              aria-label="GTC Logo"
              tabIndex={0}
              role="contentinfo"
              className="w-40 md:w-[200px] inline-block cursor-pointer"
            />
          </Link>
          <button
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg bg-transparent focus:outline-none "
            aria-controls="mobile-menu-2"
            onClick={() => setShowMenu(!showMenu)}
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="2" fill={menuColor} />
              <rect y="7" width="30" height="2" fill={menuColor} />
              <rect y="14" width="30" height="2" fill={menuColor} />
            </svg>
          </button>
          <nav
            className={[
              'overflow-hidden absolute top-0 z-50 md:ml-auto  md:w-[280px] w-[280px]',
              showMenu ? 'right-0 md:right-0' : '-right-[280px] md:-right-[280px]',
              'transition-all  duration-700 ease-in-out'
            ].join(' ')}
            style={{ background: 'rgba(67,68,71,0.8)', zIndex: '1000' }}>
            <div className="pt-2  h-[360px] pb-10 flex flex-col md:flex-row justify-between text-base pl-4 pr-4 md:pr-0 md:pl-10 w-full">
              <div className="flex flex-col gap-6">
                <div className="self-end md:-mr-8 cursor-pointer z-50" onClick={() => setShowMenu(false)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.75 5.25L5.25 18.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.75 18.75L5.25 5.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div>
                  <button
                    className="w-full py-2 text-center 
                  text-white border border-white bg-transparent
                    px-6 hover:cursor-pointer text-xl "
                    role="button"
                    aria-label={'Find an advisor'}
                    onClick={() => navigate('find-an-elite-travel-agent')}>
                    Find an Advisor
                  </button>
                </div>
                <div className="flex flex-col gap-1">
                  {' '}
                  {headerLinks.map((link) => (
                    <React.Fragment key={link.name}>
                      <HeaderLink {...link} />
                      {link.child !== undefined && (
                        <div className="ml-4">
                          <HeaderLink {...link.child} />
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </nav>
    </>
  );
}

export default Header;
