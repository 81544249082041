import { useState } from 'react';
import { StoryItemViewModel } from '../../helpers/types';

interface StoryCardProps {
  story: StoryItemViewModel;
}

function StoryCard({ story }: StoryCardProps) {
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <div>
      <div className="relative shadow-lg">
        <a title={story.title} href={story.url} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          <img className="w-full object-cover h-[440px]" src={story.thumbnailImage} alt={story.title} />
        </a>

        <div
          className="text-center bg-white opacity-80 min-h-[212px]  max-h-[212px] overflow-hidden py-4 flex flex-col 
     items-center absolute w-full bottom-0 px-4 justify-between">
          <p className="m-0 text-2xl font-[Avenir-bold] text-black uppercase ">{story.title}</p>
          <p className="m-0 text-xl text-gtc47 ">{story.shortDescription}</p>
          <a
            title={story.title + '-read more'}
            href={story.url}
            className={['hover:underline cursor-pointer text-2xl', hovered ? 'underline' : ''].join(' ')}>
            Read More
          </a>
        </div>
      </div>
    </div>
  );
}

export default StoryCard;
