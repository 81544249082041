import { Link } from 'react-router-dom';

export interface HeaderLinkProps {
  to: string;
  name: string;
  group: number;
  child?: HeaderLinkProps;
}

function HeaderLink(prop: HeaderLinkProps) {
  return (
    <Link to={prop.to} className="font-thin hover:text-gray-300 hover:underline text-lg">
      {prop.name}
    </Link>
  );
}

export default HeaderLink;
