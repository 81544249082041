import { useContext, useEffect, useMemo, useState } from 'react';
import StarRatings from 'react-star-ratings';
import APIService from '../../services/services';
import { AppContext } from '../../context/AppContext';
import { AdvertismentViewModel, AgentSearchBuildUrlParameters, AgentSearchParameters, AgentViewModel } from '../../helpers/types';
import queryString from 'query-string';
import { agentSearchFilterToParam, agentSearchQueryToFilter, callMe, getStateName } from '../../helpers/functions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';

import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import EmailForm from '../layout/EmailForm';
import PhoneForm from '../layout/PhoneForm';
import AppointmentForm from '../layout/AppointmentForm';

import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import HeroImage from '../../components/HeroImage';
import DefaultButton from '../../components/DefaultButton';
import DestinationExpert from '../../components/DestinationExpert';

function Agents() {
  const appState = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [agents, setAgents] = useState<AgentViewModel[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [ads, setAds] = useState<AdvertismentViewModel[]>([]);
  const [totalAgents, setTotalAgents] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [agentName, setAgentName] = useState<string>('');
  const [expertise, SetExpertise] = useState<string>('');
  const [showMore, setShowMore] = useState<boolean>(false);
  const [hasFilters, setHasFilters] = useState<boolean>(false);

  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars
  const [sizePerpage, setSizePerpage] = useState<number>(12);
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState<AgentSearchParameters | undefined>();

  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);

  const [selectedAgent, setSelectedAgent] = useState<AgentViewModel | null>(null);

  const parsed = queryString.parse(window.location.search);

  const getAgents = () => {
    if (filters) {
      if (filters.currentPage === 1) {
        filters.returnAgents = 12;
      } else {
        filters.returnAgents = 12;
      }
      setLoading(true);
      APIService()
        .gtcService.fetchAgents(filters)
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setAds(res.data.ads);
            if (agents.length === 0 || currentPage === 1) setAgents(res.data.agents);
            else {
              setAgents([...agents.concat(res.data.agents)]);
            }
            setTotalAgents(res.data.totalAgents);
          } else {
            setAgents([]);
            setAds([]);
            setTotalAgents(0);
          }
        });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const getMoreLinks = () => (
    <>
      {filters?.destination ||
      filters?.agentState ||
      filters?.agentMetro ||
      filters?.agentCity ||
      filters?.agentMetro ||
      filters?.interest ||
      filters?.agentSupplier ? (
        <div className="text-base h-screen">
          <p className="font-bold text-lg">
            Sorry, it doesn&apos;t look like we have any agents matching your exact search.
            <br />
            Try simplifying your search by selecting one of the options below:
          </p>
          {filters?.destination ? (
            <div>
              <a
                className="text-orange hover:text-white font-bold"
                href={`/find-an-elite-travel-agent?slctDestination=${encodeURIComponent(filters?.destination)}`}>
                {`Travel agents who book travel to ${filters?.destination}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentState ? (
            <div>
              <a className="text-orange hover:text-white font-bold" href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}`}>
                {`Travel agents located in ${getStateName(filters?.agentState)}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentMetro ? (
            <div>
              <a
                className="text-orange hover:text-white font-bold"
                href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}&slctMetro=${encodeURIComponent(filters?.agentMetro)}`}>
                {`Travel agents located in ${filters?.agentMetro} Metro`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentCity ? (
            <div>
              <a
                className="text-orange hover:text-white font-bold"
                href={`/find-an-elite-travel-agent?slctState=${filters?.agentState}${
                  filters?.agentMetro.length > 0 ? `&slctMetro=${encodeURIComponent(filters?.agentMetro)}` : ''
                }&slctCity=${encodeURIComponent(filters?.agentCity)}`}>
                {`Travel agents located in ${filters?.agentCity}, ${filters?.agentState}`}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.interest ? (
            <div>
              <a
                className="text-orange hover:text-white font-bold"
                href={`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(filters?.interest)}`}>
                {`${filters?.interest} Travel agents `}
              </a>
            </div>
          ) : (
            ''
          )}
          {filters?.agentSupplier ? (
            <div>
              <a
                className="text-orange hover:text-white font-bold"
                href={`/find-an-elite-travel-agent?slctSupplier=${encodeURIComponent(filters?.agentSupplier)}`}>
                {`Travel agents working with ${filters?.agentSupplier}`}
              </a>
            </div>
          ) : (
            ''
          )}
          <div>
            <a className="text-orange hover:text-white font-bold" href="/find-an-elite-travel-agent">
              Restart your search for the perfect travel agent.
            </a>
          </div>
        </div>
      ) : (
        getMoreMessage()
      )}
    </>
  );

  const renderFilter = () => (
    <section
      className="py-4 md:py-10 flex flex-col gap-4  text-white"
      style={{
        backgroundColor: `rgb(0, 0, 0, 0.7)`
      }}>
      <div className="mx-auto 2xl:container flex flex-col gap-2 w-full md:px-12 px-4">
        <div className=" text-xl">Where Would You Like To Go?</div>
        <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-10">
          <div className="flex-1">
            <div className="relative  flex w-full flex-wrap items-stretch">
              <div className="flex-1">
                <DestinationExpert
                  callback={(dest: string) => {
                    if (filters) {
                      if (location.search === '') navigate(`/find-an-elite-travel-agent?slctDestination=${encodeURIComponent(dest)}`);
                      else {
                        let q = queryString.exclude(location.search, ['slctDestination']);
                        if (!q.includes('?')) {
                          q = '?' + q + `slctDestination=${encodeURIComponent(dest)}`;
                        } else {
                          q = q + `&slctDestination=${encodeURIComponent(dest)}`;
                        }
                        navigate(`/find-an-elite-travel-agent${q}`);
                      }
                    }
                  }}
                />
              </div>
              <button
                className="h-10 bg-black relative z-[2] flex items-center  px-4 py-2.5 text-xs font-medium uppercase leading-tight
                 text-white shadow-md transition duration-150 ease-in-out  hover:bg-gray-900 active:shadow-lg border border-solid border-black"
                type="button"
                id="button-addon1">
                <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex-1">
            <div className="relative flex w-full flex-wrap items-stretch ">
              <input
                type="search"
                className="h-10 relative m-0 -mr-0.5 placeholder:text-gray-500  block w-[1px] min-w-0 flex-auto 
                bg-white focus:bg-white border border-solid border-neutral-300 
                 bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] 
                 text-black outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-700  dark:focus:border-primary"
                placeholder="Search by Agent"
                aria-label="Search"
                value={agentName}
                onKeyDown={(event) => {
                  if (filters && event.key === 'Enter') {
                    // setFilters({ ...filters, agentName: agentName });
                    if (location.search === '') navigate(`/find-an-elite-travel-agent?txtAgentName=${encodeURIComponent(agentName)}`);
                    else {
                      let q = queryString.exclude(location.search, ['txtAgentName']);
                      if (!q.includes('?')) {
                        q = '?' + q + `txtAgentName=${encodeURIComponent(agentName)}`;
                      } else {
                        q = q + `&txtAgentName=${encodeURIComponent(agentName)}`;
                      }
                      navigate(`/find-an-elite-travel-agent${q}`);
                    }
                    //navigate(`/find-an-elite-travel-agent?txtAgentName=${agentName}`);
                  }
                }}
                onChange={(e: any) => {
                  setAgentName(e.target.value);
                }}
              />

              <button
                className="h-10 bg-black relative z-[2] flex items-center  px-4 py-2.5 text-xs font-medium 
                uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out 
                hover:bg-gray-900  active:shadow-lg   border border-solid border-black"
                type="button"
                id="button-addon1"
                onClick={() => {
                  if (filters) {
                    // setFilters({ ...filters, agentName: agentName });
                    if (location.search === '') navigate(`/find-an-elite-travel-agent?txtAgentName=${encodeURIComponent(agentName)}`);
                    else {
                      let q = queryString.exclude(location.search, ['txtAgentName']);
                      if (!q.includes('?')) {
                        q = '?' + q + `txtAgentName=${encodeURIComponent(agentName)}`;
                      } else {
                        q = q + `&txtAgentName=${encodeURIComponent(agentName)}`;
                      }
                      navigate(`/find-an-elite-travel-agent${q}`);
                    }
                  }
                  // navigate(0);
                }}>
                <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex-1">
            <div className="relative flex w-full flex-wrap items-stretch">
              <select
                className={[
                  `h-10 relative m-0  block w-[1px] min-w-0 flex-auto  bg-white focus:bg-white border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6]
                  outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary
                dark:focus:border-primary text-black`
                ].join(' ')}
                aria-label="Search Travel Interest Experts"
                onChange={(e: any) => {
                  if (filters) {
                    // setFilters({ ...filters, interest: encodeURIComponent(e.target.value) });
                    if (location.search === '') navigate(`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(e.target.value)}`);
                    else {
                      let q = queryString.exclude(location.search, ['slctInterest']);
                      if (!q.includes('?')) {
                        q = '?' + q + `slctInterest=${encodeURIComponent(e.target.value)}`;
                      } else {
                        q = q + `&slctInterest=${encodeURIComponent(e.target.value)}`;
                      }
                      navigate(`/find-an-elite-travel-agent${q}`);
                    }
                    //navigate(`/find-an-elite-travel-agent?slctInterest=${encodeURIComponent(e.target.value)}`);
                    SetExpertise(e.target.value);
                  }
                  // navigate(0);
                }}>
                <option value="">Select Interest</option>
                {appState.specialities.map((x) => (
                  <option value={x.interest} key={x.interestId} selected={x.interest === expertise}>
                    {x.interest}
                  </option>
                ))}
              </select>

              <button
                className="h-10 bg-black relative z-[2] flex items-center px-4 py-2.5 text-xs font-medium uppercase leading-tight
                 text-white shadow-md transition duration-150 ease-in-out 
                hover:bg-gray-900 active:shadow-lg border border-solid border-black"
                type="button"
                id="button-addon1">
                <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  // eslint-disable-next-line no-unused-vars
  const getMoreMessage = () => (
    <div className="text-xl">
      {"Sorry, it doesn't look like we have any agents near you. Please"}{' '}
      <span className="underline cursor-pointer font-bold" onClick={() => navigate(`/find-an-elite-travel-agent`)}>
        start a new search
      </span>{' '}
      {'to try to find the perfect agent for you'}
    </div>
  );

  const onCall = (agent: AgentViewModel) => {
    setSelectedAgent(agent);
    setModalOpen('Phone');
    callMe(agent);
  };

  const agentCard = (agent: AgentViewModel) => {
    return (
      <React.Fragment key={agent.id}>
        <div className="flex flex-row gap-4  bg-white p-2 md:p-6  shadow-lg">
          <div className="flex flex-col gap-6 justify-between">
            <a href={agent.agentDetailsUrl}>
              <img
                src={agent.photoUrl}
                className="cursor-pointer hover:opacity-40 ease-in-out duration-500 object-cover md:h-[126px] md:w-[149px] w-full h-[158px] mx-auto imgGray"
                alt={constructAgentAlt(agent)}
              />
            </a>
            <a
              href={agent.agentDetailsUrl}
              className="  border border-black w-[160px] h-[55px] flex justify-center text-2xl items-center cursor-pointer
             mx-auto">
              Plan a trip
            </a>
          </div>
          <div className="flex flex-col gap-2 justify-between">
            <div>
              <div className="flex flex-col lg:flex-row gap-2">
                <div className="">
                  <a href={agent.agentDetailsUrl}>
                    <div className="cursor-pointer uppercase text-xl md:text-2xl   text-left">{agent.fullName}</div>
                  </a>
                </div>
                <div className="flex flex-row items-center mb-2">
                  <div className="hidden md:block md:w-40">
                    {agent.rating > 0 && (
                      <StarRatings
                        rating={agent.rating}
                        starRatedColor="#606062"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        starDimension="20"
                        starSpacing="2px"
                        name="rating"
                      />
                    )}
                  </div>
                  <div className="md:hidden">
                    {agent.rating > 0 && (
                      <StarRatings
                        rating={agent.rating}
                        starRatedColor="#606062"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        starDimension="20"
                        starSpacing="2px"
                        name="rating"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className=" text-base md:text-xl text-gtc60 text-left">{agent.interests.join(', ')}</div>
              <div className="  text-base md:text-xl text-gtc60 text-left mt-2">{agent.location}</div>{' '}
            </div>
            <div className="flex flex-row gap-6 ml-2">
              {agent.hasPhone && (
                <button
                  className={[
                    'w-[55px]  h-[55px] flex justify-center items-center  border border-black ',
                    agent.isOffline ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-white'
                  ].join(' ')}
                  disabled={agent.isOffline}
                  title={`Call ${agent.fullName}`}
                  onClick={() => onCall(agent)}
                  aria-label={`Call ${agent.fullName}`}
                  role="button"
                  tabIndex={0}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.2012 15.1124C12.198 17.1711 13.8627 18.8314 15.9238 19.823C16.0759 19.895 16.244 19.9261 16.4118 19.9134C16.5795 19.9007 16.741 19.8445 16.8805 19.7503L19.9078 17.7281C20.0416 17.6374 20.1961 17.582 20.3571 17.5672C20.518 17.5524 20.6801 17.5785 20.8281 17.6433L26.4953 20.0773C26.689 20.1579 26.8508 20.3 26.9558 20.4816C27.0609 20.6632 27.1033 20.8743 27.0766 21.0824C26.8969 22.4843 26.2126 23.7728 25.1517 24.7067C24.0908 25.6406 22.7259 26.1559 21.3125 26.1562C16.9447 26.1562 12.7558 24.4211 9.66734 21.3326C6.57885 18.2441 4.84375 14.0552 4.84375 9.68745C4.84407 8.27404 5.35938 6.90917 6.29327 5.84824C7.22716 4.78731 8.51563 4.10301 9.91758 3.92339C10.1257 3.89666 10.3368 3.93907 10.5184 4.0441C10.7 4.14913 10.842 4.31095 10.9227 4.50464L13.3566 10.1839C13.4199 10.3297 13.4464 10.4887 13.4337 10.6471C13.4211 10.8055 13.3696 10.9583 13.284 11.0921L11.2617 14.1679C11.1717 14.307 11.1188 14.4669 11.1082 14.6323C11.0976 14.7977 11.1296 14.963 11.2012 15.1124V15.1124Z"
                      stroke="black"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
              {agent.hasEmail && (
                <button
                  className={[
                    'w-[55px]  h-[55px] flex justify-center items-center  border border-black ',
                    agent.isOffline ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-white'
                  ].join(' ')}
                  disabled={agent.isOffline}
                  title={`Email ${agent.fullName}`}
                  onClick={() => {
                    setSelectedAgent(agent);
                    setModalOpen('Email');
                  }}
                  aria-label={`Email ${agent.fullName}`}
                  role="button"
                  tabIndex={0}>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.875 6.78125H27.125V23.25C27.125 23.5069 27.0229 23.7533 26.8413 23.935C26.6596 24.1167 26.4132 24.2188 26.1562 24.2188H4.84375C4.58682 24.2188 4.34042 24.1167 4.15874 23.935C3.97706 23.7533 3.875 23.5069 3.875 23.25V6.78125Z"
                      stroke="black"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M27.125 6.78125L15.5 17.4375L3.875 6.78125"
                      stroke="black"
                      strokeWidth="1.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    setFilters(agentSearchQueryToFilter(parsed as unknown as AgentSearchBuildUrlParameters, currentPage, sizePerpage));
  }, [currentPage]);

  let location = useLocation();
  useEffect(() => {
    if (filters) {
      getAgents();
    }
  }, [filters]);

  useEffect(() => {
    setAgentName('');
    SetExpertise('');
    Modal.setAppElement('#root');
    const parsed = queryString.parse(searchParams.toString());

    hasFilters;
    if (JSON.stringify(parsed) !== '{}') {
      setHasFilters(true);
    } else {
      setHasFilters(false);
    }

    setFilters(agentSearchQueryToFilter(parsed as unknown as AgentSearchBuildUrlParameters, currentPage, sizePerpage));
  }, [searchParams, currentPage]);

  const goTo = (url: string) => {
    var param = url.replace('?nearby=true&', '');
    param = param.replace('nearby=true&', '');
    param = param.replace('nearby=true', '');
    navigate(`/find-an-elite-travel-agent?${param.replace('?', '')}`);
  };

  const activeFilters = useMemo(() => {
    var selectedFilters: any[] = [];
    if (filters !== undefined) {
      const keys = Object.keys(filters);

      keys.forEach((key) => {
        if (
          filters[key as keyof AgentSearchParameters] !== '' &&
          !['agentId', 'useZipBiasing', 'currentPage', 'returnAgents'].includes(key)
        )
          selectedFilters.push({
            param: agentSearchFilterToParam(key),
            key: key.replace('A', '1'),
            value: filters[key as keyof AgentSearchParameters]
          });
      });
    }

    selectedFilters = selectedFilters.reverse();

    if (selectedFilters.length === 0)
      return (
        <div className="flex flex-row gap-2 items-center mb-6 mt-2 md:mt-0 ">
          <div className="justify-center md:justify-start flex-1">ACTIVE FILTERS | All agents</div>
        </div>
      );
    else {
      return (
        <div className="flex flex-col md:flex-row gap-2 items-center mb-6 mt-2 md:mt-0  justify-center">
          {selectedFilters
            .filter((x) => x.key.toLowerCase() !== 'nearby')
            .map((x) => {
              let filter = x.key;
              let value = x.value;

              if (filter.toLowerCase() === 'agentlanguage') {
                filter = 'Language';
              }
              if (filter.toLowerCase() === 'agentstate') {
                filter = 'Agent State';
                value = getStateName(value);
              }
              if (filter.toLowerCase() === 'agentmetro') {
                filter = 'Metro Region';
              }
              if (filter.toLowerCase() === 'agentcity') {
                filter = 'Agent City';
              }

              if (filter.toLowerCase() === 'agentsupplier') {
                filter = 'Supplier Partner';
              }

              return (
                <React.Fragment key={x.key}>
                  {filter === 'Metro Region' && selectedFilters.some((x) => x.key.toLowerCase() === 'agentcity') ? (
                    <></>
                  ) : (
                    <div className=" bg-gtc6 pl-3 pr-0 py-2 flex flex-row gap-2 items-center hover:bg-gtc3 border border-white text-center relative mx-2">
                      <div
                        className="absolute -right-3 -top-2 cursor-pointer"
                        onClick={() => {
                          var url = queryString.exclude(
                            location.search,
                            x.param === 'slctState' ? [x.param, 'slctMetro', 'slctCity'] : [x.param]
                          );
                          goTo(url);
                          if (filters) {
                            x.param === 'slctState'
                              ? setFilters({
                                  ...filters,
                                  agentState: '',
                                  agentMetro: '',
                                  agentCity: ''
                                })
                              : setFilters({ ...filters, agentState: '' });
                          }

                          //navigate(0);
                        }}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
                          <path
                            d="M17.8388 17.0606C17.8899 17.1117 17.9305 17.1724 17.9581 17.2391C17.9858 17.3059 18 17.3775 18 17.4497C18 17.522 17.9858 17.5935 17.9581 17.6603C17.9305 17.7271 17.8899 17.7877 17.8388 17.8388C17.7877 17.8899 17.7271 17.9305 17.6603 17.9581C17.5935 17.9858 17.522 18 17.4497 18C17.3775 18 17.3059 17.9858 17.2391 17.9581C17.1724 17.9305 17.1117 17.8899 17.0606 17.8388L12.5 13.2775L7.93938 17.8388C7.83618 17.942 7.69622 18 7.55028 18C7.40433 18 7.26437 17.942 7.16117 17.8388C7.05798 17.7356 7 17.5957 7 17.4497C7 17.3038 7.05798 17.1638 7.16117 17.0606L11.7225 12.5L7.16117 7.93938C7.05798 7.83618 7 7.69622 7 7.55028C7 7.40433 7.05798 7.26437 7.16117 7.16117C7.26437 7.05798 7.40433 7 7.55028 7C7.69622 7 7.83618 7.05798 7.93938 7.16117L12.5 11.7225L17.0606 7.16117C17.1638 7.05798 17.3038 7 17.4497 7C17.5957 7 17.7356 7.05798 17.8388 7.16117C17.942 7.26437 18 7.40433 18 7.55028C18 7.69622 17.942 7.83618 17.8388 7.93938L13.2775 12.5L17.8388 17.0606Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div>
                        <span className="capitalize">{filter}</span>: {value}
                      </div>
                      <i role="presentation" className="fa-solid fa-times fa-sm mt-0.5 text-black font-bold"></i>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      );
    }
  }, [filters]);

  const constructMetaTitle = () => {
    if (Array.from(searchParams).length === 0) return 'Find a Trusted Luxury Travel Agent | Global Travel Collection';
    else {
      return `See ${filters?.destination} ${filters?.interest} Travel Agents  ${
        filters?.agentState ? `in ${filters?.agentState}` : ''
      } | Global Travel Collection`;
    }
  };
  const constructMetaDesc = () => {
    if (Array.from(searchParams).length === 0)
      return 'These trusted agents can provide you with personalized recommendations, detailed knowledge of destinations and exclusive deals & amenities for your next trip.';
    else {
      return `There are ${totalAgents} Luxury Agents in ${filters?.agentState} who specialize in 
      ${filters?.destination} ${filters?.interest} who can provide recommendations, knowledge and exclusive deals.`;
    }
  };
  const constructAgentAlt = (agent: AgentViewModel) => {
    if (Array.from(searchParams).length === 0) return `${agent.fullName} Luxury Travel Agent ${agent.city},${agent.state}`;
    else {
      return `Luxury Travel ${filters?.destination} ${filters?.interest} Agent ${agent.city},${agent.state}`;
    }
  };

  return (
    <>
      <Helmet>
        <meta name="title" content={constructMetaTitle()} />
        <meta name="description" content={constructMetaDesc()} />
        <title>{constructMetaTitle()}</title>
      </Helmet>
      <section id="mainsec" className="w-full -mt-20">
        <HeroImage imageSrc="/images/advisor/hero.webp" title="Find an Advisor" />
      </section>
      <div
        className="h-full flex flex-col justify-between"
        style={{
          // backgroundImage: `url("/images/advisor/search-bg.png")`,
          backgroundSize: 'cover'
        }}>
        {renderFilter()}
        <section className="flex flex-col mt-0">
          <div
            className=" flex flex-col gap-10 items-center px-0 md:px-14 w-full mx-auto text-white pt-8 md:pt-14 pb-0"
            style={{
              backgroundColor: `rgb(0, 0, 0, 0.9)`
            }}>
            <div className="flex flex-col gap-3  text-center  2xl:container px-2 md:px-0" aria-label="Agent search" role="main">
              <h1 className="uppercase  text-3xl md:text-4xl font-normal">LUXURY TRAVEL advisors</h1>
              {/* <div className="  text-xl text-gtcbd px-4 md:px:0">
                THERE ARE {totalAgents} AGENTS MATCHING THIS SEARCH {totalAgents === 0 && !loading && getMoreMessage()}
              </div> */}
              <div className="  text-xl text-gtcbd px-2 md:px:0">
                We are the world’s largest collection of international luxury travel agencies. Approximately 1,500 GTC advisors and agencies
                are industry leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment
                industry. The combined global reach and leverage translates into value, recognition, and preferential treatment for its
                world travelers. Wherever you want to go, however you want to get there, whatever you want to do—we have been there.
              </div>

              {hasFilters && totalAgents > 0 && (
                <>
                  {activeFilters}
                  <div className="flex flex-row gap-2 justify-center items-center mt-4">
                    <div>
                      <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M24.3083 35.0111L33.5614 40.8861C34.7547 41.6389 36.2235 40.5189 35.8747 39.142L33.1942 28.6037C33.1217 28.3116 33.1332 28.005 33.2274 27.7191C33.3216 27.4333 33.4947 27.1799 33.7266 26.9881L42.0251 20.0666C43.1083 19.167 42.5575 17.3494 41.1438 17.2576L30.3118 16.56C30.0162 16.5428 29.7321 16.4398 29.4941 16.2638C29.2561 16.0877 29.0745 15.8461 28.9715 15.5686L24.9325 5.39746C24.8256 5.10353 24.6308 4.84962 24.3746 4.6702C24.1184 4.49078 23.8132 4.39453 23.5004 4.39453C23.1877 4.39453 22.8825 4.49078 22.6263 4.6702C22.3701 4.84962 22.1753 5.10353 22.0684 5.39746L18.0294 15.5686C17.9264 15.8461 17.7448 16.0877 17.5068 16.2638C17.2688 16.4398 16.9847 16.5428 16.6891 16.56L5.85709 17.2576C4.44342 17.3494 3.89264 19.167 4.97584 20.0666L13.2743 26.9881C13.5062 27.1799 13.6793 27.4333 13.7735 27.7191C13.8677 28.005 13.8792 28.3116 13.8067 28.6037L11.3282 38.3709C10.9059 40.0232 12.6684 41.3635 14.0821 40.4639L22.6926 35.0111C22.9341 34.8576 23.2143 34.776 23.5004 34.776C23.7866 34.776 24.0668 34.8576 24.3083 35.0111V35.0111Z"
                          stroke="white"
                          strokeWidth="2.9375"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="uppercase  text-2xl md:text-4xl mt-2">top advisors for you</div>
                  </div>
                </>
              )}
              <div className="mt-8">
                <div className="grid-cols-1 lg:grid-cols-2 grid gap-10  text-black">
                  {agents.slice(0, hasFilters ? 4 : 6).map((agent: AgentViewModel) => agentCard(agent))}
                </div>
              </div>
              {!showMore && !hasFilters && (
                <div className="mt-4">
                  <DefaultButton title="VIEW MORE" onClick={() => setShowMore(true)} classNames="!text-white !border-white" />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-10  text-sm md:text-base pb-20">
              {loading && (
                <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                  <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
                  <div className="text-center text-white text-xl font-semibold">Searching...</div>
                </div>
              )}
              {totalAgents === 0 && !loading && getMoreLinks()}
            </div>
          </div>
        </section>
      </div>
      {(showMore || hasFilters) && (
        <div className=" bg-gtcF2 py-10  px-0 md:px-14  w-full mx-auto flex flex-col gap-14 justify-center ">
          <div className="grid-cols-1 lg:grid-cols-2 grid gap-10  text-black 2xl:container mx-auto">
            {agents.slice(hasFilters ? 4 : 6, agents.length).map((agent: AgentViewModel) => agentCard(agent))}
          </div>

          {totalAgents > agents.length && (
            <div className="mx-auto">
              <DefaultButton title="VIEW MORE" onClick={() => setCurrentPage(currentPage + 1)} />
            </div>
          )}
        </div>
      )}
      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {selectedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...selectedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && <PhoneForm agent={selectedAgent} callback={() => setModalOpen('Email')} />}
              {modalOpen === 'Email' && <EmailForm agent={selectedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Agents;
